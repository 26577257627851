import axios from "axios";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import { ReactComponent as AccountCircleIcon } from "../../../../assets/icons/account_circle.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icon";
import InterTag from "../../../../components/Text/Inter";
import useAuthState from "../../../../context/Auth/hooks/useAuthState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { UserContextProvider } from "../../../../context/User";
import WithContextProvider from "../../../../hoc/withProvider";
import { COLORS } from "../../../../utils/colors";

const AuthUserRoot: React.FC = () => {
  const { trans } = useLocalizationState();
  const location = useLocation();
  const navigate = useNavigate();

  const { token, isResolved } = useAuthState();

  useEffect(() => {
    if (token) axios.defaults.headers["token"] = token;
  }, [token]);

  return (
    <Flex
      column
      middle
      id="auth-user-root-container"
      style={{ height: "100%" }}
    >
      <Flex
        column
        style={{
          height: "100%",
          maxWidth: "500px",
          width: "100%",
          position: "relative",
          overflow: "auto",
          backgroundColor: COLORS.gray_100,
        }}
      >
        {isResolved && <Outlet />}
        <div
          style={{
            position: "fixed",
            zIndex: 3,
            bottom: 0,
            width: "100%",
            maxWidth: "500px",
            backgroundColor: COLORS.white,
            borderRadius: "8px 8px 0px 0px",
            boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex row around>
            <Flex one center>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  if (
                    ["/stays", "/"].includes(location.pathname)
                      ? COLORS.white
                      : COLORS.secondary
                  ) {
                    navigate("/stays");
                  }
                }}
                appearance={"subtle"}
              >
                <Flex column middle>
                  <Icon
                    Element={HotelIcon}
                    size={24}
                    fill={
                      ["/stays", "/"].includes(location.pathname)
                        ? COLORS.primary
                        : COLORS.secondary
                    }
                  />
                  <InterTag
                    text={trans("Stays")}
                    size={12}
                    color={
                      ["/stays", "/"].includes(location.pathname)
                        ? COLORS.primary
                        : COLORS.secondary
                    }
                  />
                </Flex>
              </Button>
            </Flex>

            <Flex one center>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  if (
                    location.pathname === "/explore"
                      ? COLORS.white
                      : COLORS.secondary
                  ) {
                    navigate("/explore");
                  }
                }}
                appearance={"subtle"}
              >
                <Flex column middle>
                  <Icon
                    Element={SearchIcon}
                    size={24}
                    fill={
                      location.pathname === "/explore"
                        ? COLORS.primary
                        : COLORS.secondary
                    }
                  />
                  <InterTag
                    text={trans("Explore")}
                    size={12}
                    color={
                      location.pathname === "/explore"
                        ? COLORS.primary
                        : COLORS.secondary
                    }
                  />
                </Flex>
              </Button>
            </Flex>

            <Flex one center>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  if (
                    location.pathname === "/profile"
                      ? COLORS.white
                      : COLORS.secondary
                  ) {
                    navigate("/profile");
                  }
                }}
                appearance={"subtle"}
              >
                <Flex column middle>
                  <Icon
                    Element={AccountCircleIcon}
                    size={24}
                    fill={
                      location.pathname === "/profile"
                        ? COLORS.primary
                        : COLORS.secondary
                    }
                  />
                  <InterTag
                    text={trans("Profile")}
                    size={12}
                    color={
                      location.pathname === "/profile"
                        ? COLORS.primary
                        : COLORS.secondary
                    }
                  />
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </Flex>
  );
};

export default WithContextProvider(UserContextProvider, AuthUserRoot);
