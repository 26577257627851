import { toaster } from "rsuite";
import {
  nRequestStatus,
  tDeleteRequest,
  tGetRequest,
  tPostRequest,
  tPutRequest,
} from "../interfaces/apiCalls";
import { notification } from "./notifications";

// const IPV4 = "192.168.1.252";
const IPV4 = "localhost";

const formIPV4 = (hostname: string) => `http://${hostname}:5001`;

export const LOCAL_NODE_SERVER_V2 = `http://${IPV4}:5001`;
export const SERVERLESS_SERVER_V2 =
  "https://xzj3frhsdk.execute-api.eu-west-1.amazonaws.com";

export const apiAddressV2 = (useLocalApi = true) => {
  if (!useLocalApi) return SERVERLESS_SERVER_V2;
  const {
    location: { hostname },
  } = window;

  if (hostname === "localhost") return formIPV4("localhost");
  if (hostname === "127.0.0.1") return formIPV4("127.0.0.1");
  if (hostname.indexOf("192.168.1") !== -1) return formIPV4("192.168.1.5");

  return SERVERLESS_SERVER_V2;
};

export const fakeApiCall = (
  data: any,
  { timeout = 1000 }: { timeout?: number } = {}
): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return resolve(data);
    }, timeout);
  });
};

export const REQUEST_STATUS: {
  IDLE: nRequestStatus.tIdle;
  PENDING: nRequestStatus.tPending;
  RESOLVED: nRequestStatus.tResolved;
  REJECTED: nRequestStatus.tRejected;
} = {
  IDLE: "idle",
  PENDING: "pending",
  RESOLVED: "resolved",
  REJECTED: "rejected",
};

export const requestStatusIsFinal = (
  requestStatus: nRequestStatus.tStatus
): boolean => {
  return (
    [
      REQUEST_STATUS.RESOLVED,
      REQUEST_STATUS.REJECTED,
    ] as nRequestStatus.tStatus[]
  ).includes(requestStatus);
};

export const requestStatusIsLoading = (
  requestStatus: nRequestStatus.tStatus
) => {
  return requestStatus === REQUEST_STATUS.PENDING;
};

export const setGetRequestStateResolved = (
  setState: React.Dispatch<React.SetStateAction<tGetRequest<any>>>,
  data: any
) => {
  setState({
    data,
    error: null,
    status: REQUEST_STATUS.RESOLVED,
  });
};

export const setRequestStateRejected = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>,
  error?: string | null,
  toast?: boolean
) => {
  if (toast && error) {
    toaster.push(notification("error", error));
  }
  setState((prev: any) => ({
    ...prev,
    error: error || null,
    status: REQUEST_STATUS.REJECTED,
  }));
};

export const setRequestStateResolved = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>
) => {
  setState((prev: any) => ({
    ...prev,
    error: null,
    status: REQUEST_STATUS.RESOLVED,
  }));
};

export const setRequestStatePending = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>
) => {
  setState((prev: any) => ({
    ...prev,
    status: REQUEST_STATUS.PENDING,
  }));
};
