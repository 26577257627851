import { ReactComponent as ApartmentIcon } from "../../../assets/icons/apartment.svg";
import { ReactComponent as BedroomParentIcon } from "../../../assets/icons/bedroom_parent.svg";
import { ReactComponent as BungalowIcon } from "../../../assets/icons/bungalow.svg";
import { ReactComponent as CorporateFareIcon } from "../../../assets/icons/corporate_fare.svg";
import { ReactComponent as CottageIcon } from "../../../assets/icons/cottage.svg";
import { ReactComponent as GiteIcon } from "../../../assets/icons/gite.svg";
import { ReactComponent as GrassIcon } from "../../../assets/icons/grass.svg";
import { ReactComponent as HolidayVillageIcon } from "../../../assets/icons/holiday_village.svg";
import { ReactComponent as HomeWorkIcon } from "../../../assets/icons/home_work.svg";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import { ReactComponent as HouseIcon } from "../../../assets/icons/house.svg";
import { ReactComponent as LocationCityIcon } from "../../../assets/icons/location_city.svg";
import { ReactComponent as NightShelterIcon } from "../../../assets/icons/night_shelter.svg";
import { ReactComponent as VillaIcon } from "../../../assets/icons/villa.svg";

export const tipologyDetails: Record<
  string,
  {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >;
    label: string;
  }
> = {
  Agroturismo: { icon: GrassIcon, label: "Agritourism" },
  HotelApartamento: { icon: CorporateFareIcon, label: "Apartment Hotel" },
  CasaDeCampo: { icon: CottageIcon, label: "Country House" },
  TurismoDeHabitacao: { icon: HouseIcon, label: "Homestay Tourism" },
  Hotel: { icon: HotelIcon, label: "Hotel" },
  Pousada: { icon: GiteIcon, label: "Inn" },
  Apartamento: { icon: LocationCityIcon, label: "Local Apartments" },
  Moradia: { icon: NightShelterIcon, label: "Local House" },
  Quartos: { icon: BedroomParentIcon, label: "Local Rooms" },
  EstabelecimentoHospedagem: {
    icon: HomeWorkIcon,
    label: "Lodging Establishment",
  },
  EstabelecimentoHospedagemHostel: {
    icon: HomeWorkIcon,
    label: "Lodging Establishment",
  },
  HotelRural: { icon: VillaIcon, label: "Rural Hotel" },
  AldeamentoTuristico: { icon: HolidayVillageIcon, label: "Tourist Village" },
  ApartamentosTuristicos: { icon: ApartmentIcon, label: "Tourist Apartments" },
  "ParqueCampismo/Caravanismo": { icon: BungalowIcon, label: "Bungalows" },
};

export const hotelImageS3URL = (key: string) =>
  `https://noytrall-hotels-images.s3.eu-west-1.amazonaws.com/noytrall-hotels-images/${key}.jpg`;
