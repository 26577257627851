import Markdown from "markdown-to-jsx";
import React from "react";
import { IconButton, Modal, ModalProps } from "rsuite";
import { ReactComponent as ArrowBackIcon } from "../../../assets/icons/arrow_back.svg";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import Flex from "../../Flex";
import Icon from "../../Icon";
import PageBottomPadding from "../../PageBottomPadding";
import InterTag from "../../Text/Inter";
import { privacyPolicies } from "./localization";

interface iPrivacyNoticeModalWrappedProps
  extends Pick<iProps, "language" | "onClose"> {}

const PrivacyNoticeModalWrapped: React.FC<iPrivacyNoticeModalWrappedProps> = ({
  language,
  onClose,
}) => {
  const { trans } = useLocalizationState();
  return (
    <Modal.Body
      style={{ padding: 0, height: "100%", margin: 0, maxHeight: "100%" }}
    >
      <Flex column middle style={{ height: "100%" }}>
        <Flex
          column
          style={{
            height: "100%",
            maxWidth: "500px",
            width: "100%",
            position: "relative",
            overflow: "auto",
            backgroundColor: COLORS.gray_100,
          }}
        >
          <div
            style={{
              position: "fixed",
              zIndex: 3,
              top: 0,
              width: "100%",
              maxWidth: "500px",
              backgroundColor: COLORS.white,
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Flex
              color={COLORS.white}
              row
              gap={24}
              middle
              style={{ padding: "8px", height: "100%" }}
            >
              <IconButton
                onClick={onClose}
                circle
                size="xs"
                appearance="subtle"
                icon={
                  <Icon
                    Element={ArrowBackIcon}
                    size={24}
                    fill={COLORS.secondary}
                  />
                }
              />
              <InterTag
                size={20}
                color={COLORS.secondary}
                text={trans("Privacy Policy")}
                bold
              />
            </Flex>
          </div>
          <div
            style={{
              padding: "0 16px",
              height: "100%",
              marginTop: "70px",
            }}
          >
            <Markdown children={privacyPolicies[language]} />
            <PageBottomPadding />
          </div>
        </Flex>
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  language: keyof typeof privacyPolicies;
}

const PrivacyNoticeModal: React.FC<iProps> = ({
  onClose,
  language,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, size: "full", onClose, ...props }}>
      <Modal.Header></Modal.Header>
      <PrivacyNoticeModalWrapped {...{ language, onClose }} />
    </Modal>
  );
};

export default PrivacyNoticeModal;
