import { tGuest } from "../models/guest";

export const defaultUser: tGuest = {
  _id: "",
  active: true,
  country: "",
  email: "",
  facebook: { id: "" },
  friends: [],
  google: { id: "" },
  images: [],
  isAdmin: false,
  isDummy: false,
  local: { password: "" },
  method: "local",
  name: "",
  noytrallId: "",
  phoneNumber: "",
  referralCode: "",
  registered: true,
  reservations: [],
  username: "",
  forgotPassword: { token: "", validUntil: new Date() },
  createdAt: new Date(),
  updatedAt: new Date(),
  forgotPasswordToken: "",
  forgotPasswordValidUnitl: new Date(),
};
