import React from "react";
import { GuestDispatchContext } from "../";
import tHotel from "../../../models/hotel";
import { tStay } from "../../../models/reservation";

const useGuestDispatch = () => {
  const dispatch = React.useContext(GuestDispatchContext);

  if (!dispatch)
    throw Error("useGuestDispatch must be used within GuestStateContext");

  const setGuestData = (stay: tStay, hotel: tHotel) =>
    dispatch({ type: "set data", data: { stay, hotel } });

  return { setGuestData };
};

export default useGuestDispatch;
