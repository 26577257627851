import axios from "axios";
import { cloneDeep, has, round, sum } from "lodash";
import moment from "moment";
import React from "react";
import { Loader } from "rsuite";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import Flex from "../../../../components/Flex";
import ApexChart from "../../../../components/Graphs/Apex";
import Icon, { tIcon } from "../../../../components/Icon";
import { toIconProps } from "../../../../components/Icon/utils";
import InterTag from "../../../../components/Text/Inter";
import useGuestState from "../../../../context/Guest/hooks/useGuestState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetRequest, {
  tUseGetRequest,
} from "../../../../hooks/apiRequests/useGetRequest";
import useSimpleToaster from "../../../../hooks/useSimpleToaster";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import {
  MEASURES_ELECTRICITY,
  MEASURES_WATER,
  tMeasure,
  tMeasureTotal,
} from "../../../../models/measures";
import { initArray } from "../../../../utils/arrays";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { calcNumberOfNights } from "../../../../utils/dates";
import { generateGraphCategories } from "../../../../utils/graphs";
import { getErrorMessage } from "../../../../utils/httpResponses";
import { getMeasureInfo } from "../../../../utils/measures";
import PageBottomPadding from "../../../../components/PageBottomPadding";

type tRequest = {
  measures: Partial<
    Record<tMeasure, Record<"costs" | "co2" | "values", number[]>>
  >;
  co2: number[];
  costs: number[];
};

interface iSpecificSectionProps {
  icon: tIcon;
  label: string;
  measures: tMeasure[];
  request: tUseGetRequest<tRequest>;
  numberOfNights: number;
  converter: nUnitConverter.tFunction;
  totalMeasureKey: tMeasureTotal;
  graphCategories: Date[];
  mainUnit: string;
}

const SpecificSection: React.FC<iSpecificSectionProps> = ({
  request,
  icon,
  label,
  measures,
  numberOfNights,
  converter,
  totalMeasureKey,
  graphCategories,
  mainUnit,
}) => {
  const { trans } = useLocalizationState();

  const list = measures.filter(
    (measure) =>
      has(request.data.measures, measure) &&
      sum(request.data.measures[measure]?.values || [])
  );

  if (list.length === 0) return null;

  const perDay = converter(
    sum(request.data.measures[totalMeasureKey]?.values || [0]) / numberOfNights
  );
  return (
    <Flex column gap={12}>
      <Flex column>
        <Flex row gap={4} middle>
          <Icon {...{ size: 24, ...icon }} />
          <InterTag
            text={label}
            size={20}
            color={COLORS.secondary}
            asHTML
            bold
          />
        </Flex>
        <Flex row gap={4} middle>
          <div style={{ width: "24px" }} />
          <InterTag
            text={trans("Consuming $0 per day, on average", {
              parameters: [`${round(perDay.value, 2)} ${perDay.unitInHTML}`],
            })}
            asHTML
            color={COLORS.gray}
            size={12}
          />
        </Flex>
      </Flex>
      {
        <Flex row gap={16} style={{ overflowX: "auto" }}>
          {list.map((measure) => {
            const measureInfo = getMeasureInfo(measure);
            const converted = converter(
              sum(request.data.measures[measure]!.values)
            );

            return (
              <Flex
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  padding: "12px",
                  minWidth: "130px",
                }}
                column
                gap={8}
              >
                <Flex row gap={4} middle>
                  <Icon {...{ size: 24, ...measureInfo.icon }} />
                  <InterTag
                    text={trans(measureInfo.label)}
                    size={14}
                    color={COLORS.secondary}
                  />
                </Flex>
                <InterTag
                  text={`${round(converted.value, 2)} ${converted.unitInHTML}`}
                  asHTML
                  size={20}
                  color={COLORS.secondary}
                />
              </Flex>
            );
          })}
        </Flex>
      }
      <Flex color={COLORS.white} style={{ borderRadius: "8px" }}>
        <div style={{ width: "100%" }}>
          <ApexChart
            type="line"
            series={list.map((measure) => {
              const measureInfo = getMeasureInfo(measure);

              return {
                name: trans(measureInfo.label),
                data:
                  request.data.measures[measure]?.values ||
                  initArray(numberOfNights),
                color: measureInfo.color,
              };
            })}
            yaxis={{
              labels: {
                show: true,
                formatter(val: any, opts: any) {
                  return `${round(val, 2)} ${mainUnit}`;
                },
              },
            }}
            xaxis={{
              categories: graphCategories,
              labels: {
                rotate: 0,
                show: true,
                formatter(value: any, timestamp: any, opts: any) {
                  return moment(value).format("DD/MM");
                },
              },
            }}
          />
        </div>
      </Flex>
    </Flex>
  );
};

const initialState: tRequest = {
  co2: [],
  costs: [],
  measures: {},
};

const Stay: React.FC = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const request = useGetRequest<tRequest>(cloneDeep(initialState));
  const { hotel, stay, isResolved } = useGuestState();

  useEffectSafe(() => {
    if (isResolved) {
      request.pending();
      axios
        .get(`/guest-app/v2/stays/${stay._id}/consumption`)
        .then((res) => {
          console.log(res);
          const {
            data: { consumption },
          } = res;

          request.resolve(consumption);
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          toaster.error(error);
          request.reject(error);
        });
    }
  }, [toaster, trans]);

  const numberOfNights = calcNumberOfNights(stay.startAt, stay.endAt);

  const renderMain = () => {
    if (request.isLoading)
      return (
        <Flex center middle style={{ marginTop: "50px" }}>
          <Loader size="lg" />
        </Flex>
      );

    const topCards = (
      <Flex row gap={16} style={{ overflowX: "auto" }}>
        {(
          [
            {
              icon: { Element: EcoIcon, fill: COLORS.emissions },
              label: trans("Footprint"),
              convertedValue: convertMassUnit(sum(request.data.co2)),
            },
            {
              icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
              label: trans("Electricity"),
              convertedValue: convertEnergyUnit(
                sum(request.data.measures.te?.values || [0])
              ),
            },
            {
              icon: { Element: WaterDropIcon, fill: COLORS.water },
              label: trans("Water"),
              convertedValue: convertVolumeUnit(
                sum(request.data.measures.tw?.values || [0])
              ),
            },
          ] as {
            icon: tIcon;
            label: string;
            convertedValue: nUnitConverter.tUnitConverterFunction;
          }[]
        ).map(({ icon, label, convertedValue }) => (
          <Flex
            color={COLORS.white}
            style={{ borderRadius: "8px", padding: "12px", minWidth: "110px" }}
            column
            gap={8}
          >
            <Flex row gap={4} middle>
              <Icon {...{ size: 24, ...toIconProps(icon) }} />
              <InterTag size={14} color={COLORS.secondary} text={label} />
            </Flex>
            <InterTag
              text={`${round(convertedValue.value, 2)} ${
                convertedValue.unitInHTML
              }`}
              asHTML
              size={20}
              color={COLORS.secondary}
            />
          </Flex>
        ))}
      </Flex>
    );

    const categories = generateGraphCategories(
      "day",
      1,
      stay.startAt,
      stay.endAt
    );
    const co2PerDay = convertMassUnit(sum(request.data.co2) / numberOfNights);
    const co2Graph = (
      <Flex column gap={12}>
        <Flex column>
          <Flex row gap={4} middle>
            <Icon Element={EcoIcon} fill={COLORS.emissions} size={24} />
            <InterTag
              text={trans("CO<sub>2</sub> Emissions Distribution")}
              size={20}
              color={COLORS.secondary}
              asHTML
              bold
            />
          </Flex>
          <Flex row gap={4} middle>
            <div style={{ width: "24px" }} />
            <InterTag
              text={trans("Consuming $0 per day, on average", {
                parameters: [
                  `${round(co2PerDay.value, 2)} ${co2PerDay.unitInHTML}`,
                ],
              })}
              asHTML
              color={COLORS.gray}
              size={12}
            />
          </Flex>
        </Flex>
        <Flex color={COLORS.white} style={{ borderRadius: "8px" }}>
          <div style={{ width: "100%" }}>
            <ApexChart
              type="line"
              series={[
                {
                  name: trans("Total"),
                  data: request.data.co2,
                  color: COLORS.emissions,
                },
                {
                  name: trans("Electricity"),
                  data:
                    request.data.measures.te?.co2 || initArray(numberOfNights),
                  color: COLORS.energy,
                },
                {
                  name: trans("Water"),
                  data:
                    request.data.measures.tw?.co2 || initArray(numberOfNights),
                  color: COLORS.water,
                },
              ]}
              yaxis={{
                labels: {
                  show: true,
                  formatter(val: any, opts: any) {
                    return `${round(val, 2)} kg`;
                  },
                },
              }}
              xaxis={{
                categories,
                labels: {
                  rotate: 0,
                  show: true,
                  formatter(value: any, timestamp: any, opts: any) {
                    return moment(value).format("DD/MM");
                  },
                },
              }}
            />
          </div>
        </Flex>
      </Flex>
    );

    return (
      <Flex column gap={24}>
        {topCards}
        {co2Graph}
        <SpecificSection
          graphCategories={categories}
          icon={{ Element: ElectricBoltIcon, fill: COLORS.energy }}
          label={trans("Energy Distribution")}
          measures={MEASURES_ELECTRICITY}
          request={request}
          converter={convertEnergyUnit}
          numberOfNights={numberOfNights}
          totalMeasureKey="te"
          mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
        />
        <SpecificSection
          graphCategories={categories}
          icon={{ Element: WaterDropIcon, fill: COLORS.water }}
          label={trans("Water Distribution")}
          measures={MEASURES_WATER}
          request={request}
          converter={convertVolumeUnit}
          numberOfNights={numberOfNights}
          totalMeasureKey="tw"
          mainUnit={DEFAULT_WATER_MEASURE_UNIT}
        />
      </Flex>
    );
  };

  return (
    <Flex column middle>
      <Flex column gap={12} style={{ marginTop: "20px", width: "90%" }}>
        <Flex column>
          <InterTag size={20} bold color={COLORS.secondary} text={hotel.name} />
          <InterTag
            size={12}
            color={COLORS.gray}
            text={`${numberOfNights} nights • ${[stay.startAt, stay.endAt]
              .map((d) => moment(d).format("MMMM DD, YYYY"))
              .join(" to ")}`}
          />
          <InterTag text={`#${stay.code}`} size={12} color={COLORS.primary} />
        </Flex>
        {renderMain()}
        <PageBottomPadding size={100} />
      </Flex>
    </Flex>
  );
};

export default Stay;
