import axios from "axios";
import { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import useAuthState from "./context/Auth/hooks/useAuthState";
import AuthGuestRoot from "./pages/auth/guest/root";
import Stay from "./pages/auth/guest/stay";
import Explore from "./pages/auth/user/explore";
import Profile from "./pages/auth/user/profile";
import AuthUserRoot from "./pages/auth/user/root";
import Stays from "./pages/auth/user/stays";
import ForgotPassword from "./pages/noAuth/forgotPassword";
import Login from "./pages/noAuth/login";
import LoginAsGuest from "./pages/noAuth/loginAsGuest";
import Register from "./pages/noAuth/register";
import NoAuthRoot from "./pages/noAuth/root";
import "./styles/global.css";
import "./styles/rsuite/index.css";
import { apiAddressV2 } from "./utils/apiCall";
import ResetPassword from "./pages/noAuth/resetPassword";

const routerNoAuth = createBrowserRouter([
  {
    path: "/",
    element: <NoAuthRoot />,
    children: [
      { index: true, element: <Login /> },
      { path: "login", element: <Login /> },
      { path: "forgot", element: <ForgotPassword /> },
      { path: "login-guest", element: <LoginAsGuest /> },
      { path: "register", element: <Register /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ],
  },
]);

const routerAuthUser = createBrowserRouter([
  {
    path: "/",
    element: <AuthUserRoot />,
    children: [
      { index: true, element: <Stays /> },
      { path: "stays", element: <Stays /> },
      { path: "explore", element: <Explore /> },
      { path: "profile", element: <Profile /> },
      { path: "*", element: <Navigate to="/stays" replace /> },
    ],
  },
]);

const routerAuthGuest = createBrowserRouter([
  {
    path: "/",
    element: <AuthGuestRoot />,
    children: [
      { index: true, element: <Stay /> },
      { path: "stay", element: <Stay /> },
      { path: "explore", element: <Explore /> },
      { path: "*", element: <Navigate to="/stay" replace /> },
    ],
  },
]);

const AppWrapped: React.FC = () => {
  const { isResolved, token, type } = useAuthState();

  if (!isResolved) return null;

  if (!token) return <RouterProvider router={routerNoAuth} />;

  if (type === "user") return <RouterProvider router={routerAuthUser} />;

  if (type === "guest") return <RouterProvider router={routerAuthGuest} />;

  return null;
};

function App() {
  useEffect(() => {
    axios.defaults.baseURL = apiAddressV2();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <AppWrapped />
    </div>
  );
}

export default App;
