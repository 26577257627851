import React from "react";
import { UserStateContext } from "../";
import { REQUEST_STATUS } from "../../../utils/apiCall";

const useUserState = () => {
  const state = React.useContext(UserStateContext);

  if (!state) throw Error("useUserState must be used within UserStateContext");

  const {
    data: { profile },
    status,
  } = state;

  return {
    profile,
    isLoading: status === REQUEST_STATUS.PENDING,
    isResolved: status === REQUEST_STATUS.RESOLVED,
    isRejected: status === REQUEST_STATUS.REJECTED,
  };
};

export default useUserState;
