export const termsOfService: Record<"en" | "pt", string> = {
  en: `## 1. Introduction

These Terms and Conditions govern the access to and use of the website www.noytrall.com, managed by Noytrall Technologies, Lda. (hereinafter "Noytrall"), located at Rua de São Martinho, nº 8, Lourinha de Baixo, 3450-092 Marmeleira. Any legal matters concerning the website can be consulted in the Privacy Policy and Cookie Policy sections, which are integral parts of these Terms and Conditions.

The use of this website by any user shall be governed by the following Terms and Conditions, and implies acceptance thereof by the user. Should the user reject these Terms and Conditions, they must cease using the website.

Noytrall reserves the right to change or update these Terms and Conditions, as well as the Privacy Policy and Cookie Policy, in whole or in part. Any changes or updates to these Terms and Conditions, as well as the Privacy Policy and Cookie Policy, shall take effect immediately upon publication in the respective section of the website. Users are advised to regularly check the sections on Terms and Conditions, Privacy Policy, and Cookie Policy for the most updated versions.

Access to and use of the website are provided exclusively for personal use and for the promotion of Noytrall's activities.

Noytrall reserves the right to discretely assess compliance with these Terms and Conditions by any user. Violation of the Terms and Conditions may result in the revocation of the license granted by Noytrall under this Clause 1, and Noytrall may exercise its rights to the fullest extent permitted by law.

Users agree to indemnify and hold harmless Noytrall, its legal representatives, partners, and employees, from any damages, liabilities, claims, or demands for compensation, including expenses and legal costs, brought by third parties as a result of the user's use of the website in violation of these Terms and Conditions, and/or due to the breach of the conditions set forth herein, and/or arising from non-compliance with the representations and warranties contained in these Terms and Conditions.

## 2. License

Notwithstanding the provisions of the other clauses in these Terms and Conditions, Noytrall grants any user the right to use this website solely for personal or informational purposes, as provided in these Terms and Conditions. The license to use may be revoked at Noytrall's discretion and at any time. Under the law and these Terms and Conditions, the user may not copy, use, transfer, rent, sublicense, alter, adapt, attempt to modify or alter the source code, perform reverse engineering, decompile or disassemble, in whole or in part, the content of the website. By accessing the website, the user acknowledges and accepts that the content thereof may be incomplete, inaccurate, not up-to-date, or may not meet their needs and requirements.

## 3. Content and Limitation of Noytrall's Liability

The access and use of the website, including the viewing of web pages, are activities performed by users.

Users are solely and exclusively responsible for the use of the website and its contents. Noytrall is not responsible, nor does it assume any liability for the use (if any) of the website in a manner that does not comply with current legal provisions by the users.

Specifically, users are solely responsible for communicating incorrect, false, or third-party information or data without their consent, as well as for their misuse. The contents of the website may contain inaccuracies or typographical errors. Noytrall is not responsible, nor can it be held liable, in any way, for inaccuracies and errors, damages (if any) caused or resulting from the use of information derived from the website or through the website by users. The user is solely and exclusively responsible for evaluating the information and contents obtained through the website.

The website and all information and content published therein may be changed by Noytrall at any time, periodically and/or without prior notice. Considering that any content that may be downloaded or otherwise obtained results from the user's free decision and is done at their own risk, any damage (if any) to computers or loss of data resulting from downloading operations performed by the user will be the user's sole responsibility and cannot be attributed to Noytrall. Noytrall assumes no responsibility for damages (if any) resulting from the inability to access services provided through the website or for data (if any) caused by viruses, damaged files, errors, omissions, service interruptions, content cancellation, Internet-related issues, service providers or telecommunications and/or telematics connections, unauthorized access, data changes, or related to the non-existence and/or poor functioning of user electronic devices.

The user is responsible for the safekeeping and correct use of their personal information, as well as for any damage or loss caused to Noytrall or third parties resulting from incorrect use, loss, or theft of personal information.

In cases of registration, the user is responsible for the safekeeping and correct use of their credentials, which allow access to the reserved area of ​​services. Under these circumstances, the user will be responsible for creating their username and password. The user is also responsible for providing specific information, which must be accurate and up-to-date. The user may not choose a username belonging to a third party in order to use their identity. Similarly, the user may not use the username of a third party without their express consent.

The user must ensure the confidentiality of their password and must not share it with third parties.

The user is fully and exclusively responsible for any actions taken through their user account, directly or through third parties authorized by the user. Noytrall may revoke the license, at its discretion and at any time, if any abusive, fraudulent, or illegal acts are identified.

Noytrall has adopted appropriate technical and organizational measures to safeguard the security of the services provided through the website, ensure the integrity of electronic traffic data related to the forms of unauthorized use or knowledge, and to prevent risks of dissemination, destruction, or loss of data and confidential or non-confidential information of its users, contained on the website, or related to unauthorized access - or in breach of applicable legal provisions - to such personal data and information.

Noytrall does not guarantee, nor can it ensure that the website is free from viruses or any other elements that may adversely affect technology.

## 4. Access to the Website. Interruption and Suspension

Noytrall makes every effort to provide continuous access to the website; however, access to the website may be suspended, limited, or interrupted at any time for any reason. Access to the website may occasionally be limited to allow for recovery, maintenance, or introduction of new features or services. Noytrall will make its best efforts to restore access as quickly as possible. Noytrall reserves the right to block access to any material and/or remove any material that may, in its discretionary understanding, provoke a violation of these Terms and Conditions.

Noytrall reserves the right to terminate user accounts that violate these Terms and Conditions, including situations where it is found that the user is using IP proxy addresses to conceal the use of multiple accounts, or intends to be identified as being in a country different from where they actually are, or causes disruptions to the operation of the website by any means.

## 5. Payment and Transactions

For the purchase of any services or products offered on our platform, we use Stripe as our payment processor. Stripe is crucial for handling all payment-related functions and also provides analytics and other business services.

We use Stripe for payment, analytics, and other business services. Stripe collects and processes personal data, including identifying information about the devices that connect to its services. Stripe uses this information to operate and improve the services it provides to us, including for fraud detection and prevention. You can learn more about Stripe and its processing activities via [Stripe's privacy policy](https://stripe.com/privacy).

Please ensure that you review Stripe’s terms and privacy policy to understand how they handle your personal information. Your agreement to our Terms and Conditions includes your consent to this processing of your data by Stripe for the purposes of completing transactions on our platform.

## 6. Protection of Personal Data

Users are advised to read and review the Privacy Policy, which is an integral part of these Terms and Conditions, to understand how the website collects and uses users' personal data and for what purposes.

## 7. Intellectual Property Rights

All rights are reserved. The website and all its contents, including but not limited to, articles, opinions, other texts, lists, user manuals, photographs, designs, images, video or audio clips, or marketing texts, as well as trademarks, logos, domain names, and any other elements that may be covered by property rights (including source codes) and/or other forms of intellectual property rights (hereinafter "Material"), are the property of Noytrall or third parties, and are protected against unauthorized use, copying, or disclosure by national laws and international treaties on Intellectual Property Rights. None of the provisions of the Terms and Conditions and/or the contents of the website may be interpreted as implicitly conferring, accepting, or in any way licensing the right to use any Material by any means, without the prior written consent of Noytrall or the third-party owner of the Material or intellectual property rights published on the website. The use, copying, reproduction, alteration, republication, updating, downloading, emailing, transmitting, distributing, or duplicating, or any other abusive act of the Material not specifically identified but of identical nature, is prohibited. As a user of the website, the user agrees not to use the Material for illegal purposes and not to violate Noytrall's rights.

However, the user may view and display the content of the website and/or the Material on a computer screen, store such content in electronic format on disk (but not on a server or Internet-connected memory device), or print a copy of such content for personal and non-commercial use, provided that all intellectual property rights notices are maintained.

Access to the website does not grant the user any rights over the contents provided by Noytrall.

## 8. Links to Other Websites

If the website provides links ("Links") to third-party websites or to content provided by third parties ("Other websites"), Noytrall hereby informs that the provision of links to such Other websites is included solely for informational purposes and for the convenience of the User. Noytrall does not control Other websites and, therefore, is not responsible for such Other websites or for the contents or products that Other websites may provide (including but not limited to references to social networks). Noytrall is not liable for any damages or losses that may result from the User's use of Other websites, as well as for the handling of personal data during internet browsing operations. Access to any Other website through a link on the Noytrall website is solely at the User's own risk and responsibility.

Therefore, the User should exercise particular caution when connecting to Other websites through links on the Noytrall website and carefully read their respective terms and conditions and privacy policies.

## 9. User Representations and Warranties

The user acknowledges and declares that:

- they have read and understood these Terms and Conditions;
- they will refrain from reproducing, duplicating, copying, selling, reselling, or commercially exploiting the website or its contents, or any part thereof, as well as from using or reproducing the trademarks or any intellectual or industrial property rights of Noytrall;
- they will not publish or use false, defamatory, or libelous information;
- they will refrain from using, directly or indirectly, the services or the website for purposes contrary to law or inconsistent with the principles set forth in these Terms and Conditions;
- they will not propagate viruses, spyware, adware, rootkits, backdoors, Trojan horses, or similar computer threats;
- they will not use software or other automatic or manual mechanisms to copy or gain access to the control of the website or its content.

## 10. Miscellaneous Provisions

If any provision of these Terms and Conditions is found to be invalid, such provision shall be deemed severed from these Terms and Conditions, and the remaining provisions shall remain in full force and effect.

This agreement shall be governed by Portuguese law and shall be interpreted in accordance with Portuguese law, in conformity with contracts made and executed in this country. In the absence of amicable agreement between the parties, any disputes or disagreements of any nature relating to the formation, execution, or interpretation of this Agreement shall be definitively settled by the Judicial Courts, with express waiver of any other jurisdiction.

The user may contact us regarding any issues related to the Terms and Conditions or the use of the website, either by registered letter with acknowledgment of receipt to the address Rua de São Martinho, nº 8, Lourinha de Baixo, 3450-092 Marmeleira or by email.`,
  pt: `## 1. Introdução

Os presentes Termos e Condições regulam o acesso e utilização do website www.noytrall.com, da responsabilidade da Noytrall Technologies, Lda. (adiante “Noytrall”), com sede na Rua de São Martinho, nº 8, Lourinha de Baixo, 3450-092 Marmeleira. Quaisquer questões legais referentes ao website poderão ser consultadas nas secções Política de Privacidade e Política de Cookies, os quais constituem parte integrante dos presentes Termos e Condições.

A utilização deste website por qualquer utilizador será regulada pelos seguintes Termos e Condições, implicando a sua utilização uma aceitação dos mesmos pelo utilizador. Caso o utilizador rejeite os presentes Termos e Condições, deve cessar a utilização do mesmo.

A Noytrall poderá alterar ou atualizar, no todo ou em parte, os presentes Termos e Condições, bem como a Política de Privacidade e a Política de Cookies. Quaisquer alterações ou atualizações dos presentes Termos e Condições, bem como os da Política de Privacidade e Política de Cookies, entrarão em vigor logo que publicados na respectiva secção do website. Os utilizadores são aconselhados a consultar regularmente as secções dos Termos e Condições, da Política de Privacidade e da Política de Cookies para verificarem as versões mais atualizadas.

O acesso e a utilização do website são disponibilizados exclusivamente para utilização pessoal e para a promoção da atividade da Noytrall.

A Noytrall reserva o direito de avaliar discricionariamente o cumprimento dos presentes Termos e Condições por parte de qualquer utilizador. A violação dos Termos e Condições poderá implicar a retirada da licença de utilização concedida pela Noytrall, nos termos da presente Cláusula 1 e, bem assim, exercer os seus direitos na máxima extensão possível permitida por lei.

Os utilizadores comprometem-se a indemnizar e a exonerar a Noytrall, bem como legais representantes, parceiros e trabalhadores, de quaisquer danos, responsabilidades, reclamações ou pedidos de compensação, incluindo despesas e custos de representação, peticionadas por terceiros em consequência da utilização do website em termos desconformes com os presentes Termos e Condições, e/ou em virtude da violação das condições previstas nos mesmos, e/ou resultantes de incumprimento das representações e garantias constantes dos presentes Termos e Condições.

## 2. Licença

Sem prejuízo do constante nas demais cláusulas dos presentes Termos e Condições, a Noytrall autoriza qualquer utilizador a utilizar este website apenas para fins pessoais ou informativos, conforme previsto nos presentes Termos e Condições. A licença de utilização poderá ser revogada pela discricionariamente e em qualquer momento. Nos termos da lei e dos presentes Termos e Condições, o utilizador não poderá copiar, utilizar, transferir, alugar, sublicenciar, alterar, adaptar, tentar modificar ou alterar o código fonte, efetuar operações de engenharia reversa, descompilar ou desmontar, no todo ou em parte, o conteúdo do website. Ao aceder ao website, o utilizador reconhece e aceita que o conteúdo do mesmo poderá estar incompleto, impreciso, não atualizado, ou que poderá não satisfazer as suas necessidades e requisitos.

## 3. Conteúdo e Limitação de Responsabilidade da Noytrall

O acesso e utilização do website, incluindo a exibição de páginas web, constituem atividades executadas pelos utilizadores.

Os utilizadores são os únicos e exclusivos responsáveis pela utilização do website e dos seus conteúdos. A Noytrall não é responsável, nem assume responsabilidades pela utilização (se existente) do website desconforme às disposições legais em vigor pelos utilizadores de maneira.

Em concreto, os utilizadores são os únicos responsáveis pela comunicação de informação ou dados incorretos, falsos, ou de terceiros, sem o seu consentimento, bem como pela utilização incorreta dos mesmos.
Os conteúdos do website podem conter incorreções ou gralhas. A Noytrall não é responsável, nem poderá ser responsabilizada, por qualquer meio ou modo, pelas incorreções e erros, danos (se existentes) causados ou resultantes da utilização de informação derivada do website ou através do website por parte dos utilizadores. O utilizador é o único e exclusivo responsável pela avaliação da informação e conteúdos obtidos através do website.

O website e toda a informação e conteúdos publicados no mesmo podem ser alterados pela Noytrall a qualquer momento, periodicamente e/ou sem aviso prévio.
Considerando que quaisquer conteúdos que venham a ser descarregados ou por outro meio obtidos, resultam de decisão livre do utilizador e são efetuados por sua conta e risco, qualquer dano (caso exista) aos computadores ou perda de dados resultantes de operações de descarregamento efetuada pelo utilizador, será da exclusiva responsabilidade do utilizador e não poderá ser imputada à Noytrall. A Noytrall não assume qualquer responsabilidade por danos (caso existam) resultantes da impossibilidade de acesso aos serviços disponibilizados através do website ou por dados (caso existam) causados por vírus, ficheiros danificados, erros, omissões, interrupções do serviço, cancelamento de conteúdos, questões relacionadas com a Internet, prestadores de serviços ou com a ligação telefónica e/ou telemática, acessos não autorizados, alterações de dados, ou relacionados com a inexistência e/ou deficiente funcionamento de dispositivos electrónicos do utilizador.

O utilizador é responsável pela guarda e correta utilização da sua informação pessoal, bem como responsável por qualquer dano ou prejuízo causado à Noytrall ou a terceiras partes, resultante da utilização incorreta, perda ou furto da informação pessoal.

Nos casos de registo, o utilizador é responsável pela guarda e correta utilização das suas credenciais, as quais permitem o acesso à área reservada dos serviços. Nessas circunstâncias, o utilizador será responsável por criar o seu nome de utilizador e password. O utilizador será ainda responsável pela prestação de informação específica, a qual deverá ser correta e atualizada. O utilizador não poderá escolher um nome de utilizador pertencente a terceiro com o intuito de utilizar a sua identidade. O utilizador não poderá, do mesmo modo, utilizar o nome de utilizador de um terceiro sem o seu consentimento expresso.

O utilizador deverá garantir a confidencialidade da sua password e não deverá partilhar a mesma com terceiros.

O utilizador é inteira e exclusivamente responsável por quaisquer ações efetuadas através da sua conta de utilizador, diretamente ou mediante terceiros autorizados pelo utilizador. A Noytrall poderá revogar a licença, discricionariamente e a qualquer momento, caso seja identificada a prática de qualquer ato abusivo, fraudulento ou ilegal.

A Noytrall adotou as medidas técnicas e organizacionais adequadas a salvaguardar a segurança dos serviços por si prestados através do website, assegurar a integridade dos dados de tráfego electrónico relacionados com as formas de utilização ou conhecimento não autorizadas, bem como para evitar riscos de disseminação, destruição ou perda de dados e informação confidencial ou não confidencial dos seus utilizadores, constantes do website, ou relacionados com o acesso – não autorizado ou em incumprimento das disposições legais aplicáveis – aos referidos dados pessoais e informação.

A Noytrall não garante, nem poderá garantir que o website esteja livre de vírus ou de qualquer outro elemento que possa afetar negativamente a tecnologia.

## 4. Acesso ao Website. Interrupção e Suspensão

A Noytrall envida os seus melhores esforços para disponibilizar o acesso contínuo ao website, contudo, o acesso ao website poderá ser suspenso, limitado ou interrompido a qualquer momento independentemente do motivo. O acesso ao website poderá ser limitado ocasionalmente para permitir a recuperação, manutenção ou introdução de novas funcionalidades ou serviços. A Noytrall envidará os melhores esforços para recuperar o acesso com a maior brevidade possível. A Noytrall reserva o direito de bloquear o acesso a qualquer material e/ou remover qual material que possa, no seu entendimento discricionário, provocar a violação dos presentes Termos e Condições.

A Noytrall reserva o direito de encerrar as contas de utilizadores que violem os presentes Termos e Condições, incluindo nas situações em que seja verificado que o utilizador se encontra utilizar proxy IP addresses no intuito de esconder a utilização de contas múltiplas, ou pretenda ser identificado como estando num país distinto do qual efetivamente se encontra, ou cause perturbações ao funcionamento do website por qualquer meio.

## 5. Proteção de Dados Pessoais

Os utilizadores são aconselhados a ler e a analisar a Política de Privacidade, a qual constitui parte integrante dos presentes Termos e Condições, para compreender como o website recolhe e utiliza os dados pessoais dos utilizadores e quais as respetivas finalidades.

## 6. Direitos de Propriedade Intelectual

Todos os direitos são reservados. O website e todos os seus conteúdos, incluindo sem limitar, artigos, opiniões, outros textos, listas, manuais de utilização, fotografias, desenhos, imagens, clips vídeos ou áudio ou textos de marketing, bem como marcas, logótipos, nomes de domínio, e quaisquer outros elementos que possam ser abrangidos por direitos de propriedade (incluindo os códigos fonte) e/ou outras modalidades de direitos de propriedade intelectual (doravante “Material”), são propriedade da Noytrall ou de terceiros, e estão protegidos contra a utilização, a cópia ou a divulgação não autorizada pelas leis nacionais e tratados internacionais relativos aos Direitos de Propriedade Intelectual. Nenhum das disposições dos Termos e Condições e/ou dos conteúdos constantes do website poderão ser interpretados como implicitamente conferindo, aceitando ou por qualquer meio licenciado o direito de utilização de qualquer Material por qualquer meio, sem o prévio consentimento escrito da Noytrall ou do terceiro proprietário do Material ou dos direitos de propriedade intelectual publicados no website. A utilização, cópia, reprodução, alteração, republicação, atualização, descarregamento, envio por e-mail, transmissão, distribuição ou duplicação, ou qualquer outro ato abusivo do Material não especificamente identificado, mas de idêntica natureza, são proibidos. Na sua qualidade de utilizador do website, o utilizador compromete-se a não utilizar o Material para fins ilegais e a não violar os direitos da Noytrall.

O utilizador poderá, contudo, visualizar e exibir o conteúdo do website e/ou o Material no ecrã de um computador, armazenar tal conteúdo em formato electrónico no disco (mas não num servidor nem num dispositivo de memória conectado à Internet) ou imprimir uma cópia de tais conteúdos para sua utilização pessoal e não comercial, devendo contudo manter todas as informações relacionadas com os direitos de propriedade intelectual.

O acesso ao website não confere ao utilizador qualquer direito sobre os conteúdos disponibilizados pela Noytrall.

## 7. Links para outros Websites

Caso o website disponibilize ligações (“Links”) para websites de terceiros ou para conteúdos disponibilizados por terceiros (“Outros websites”), a Noytrall pelo presente informa que a disponibilização de ligações a tais Outros websites são incluídas apenas para fins exclusivamente informativos e para conveniência do Utilizador. A Noytrall não controla os Outros Websites e, por isso, não se responsabiliza por tais Outros Websites ou pelos conteúdos ou produtos que os Outros Websites (incluindo sem limitar, a referência a redes sociais) e não se responsabiliza por quaisquer danos ou prejuízos que possam resultar da utilização dos Outros Websites pelo Utilizador, bem como quanto ao tratamento de dados pessoais durante as operações de navegação na internet. O acesso a qualquer Outro Website, mediante uma ligação existente no website da Noytrall, será efetuado por exclusiva responsabilidade e risco do Utilizador.

O Utilizador deverá, assim, prestar particular atenção quando se conecte a Outros Websites através de ligações existentes no website da Noytrall e ler com atenção os respetivos termos e condições e políticas de privacidade.

## 8. Representações e Garantias do Utilizador

O utilizador reconhece e declara que:

- leu e compreendeu os presentes Termos e Condições;
- se absterá de reproduzir, duplicar, copiar, vender, revender ou por qualquer meio explorar comercialmente o website ou os seus conteúdos, ou parte dos mesmos, bem como se absterá de utilizar ou reproduzir as marcas ou quaisquer direitos de propriedade intelectual ou industrial da Noytrall;
- não publicará ou utilizará informação falsa, injuriosa ou difamatória;
- se absterá de utilizar, direta ou indiretamente, os serviços ou o website para fins contrários à lei ou desconforme aos princípios constantes dos presentes Termos e Condições;
- não propagará vírus, spyware, adware, rootkit, backdoor ou vírus Trojan ou outras ameaças informáticas similares;
- não utilizará software ou outros mecanismos automáticos ou manuais para copiar ou aceder ao controlo do website ou do seu conteúdo.

## 9. Disposições Diversas

A nulidade de alguma das disposições constantes nos presentes Termos e Condições ter-se-á por suprimida destes, permanecendo as restantes disposições do mesmo integralmente em vigor e produzindo os seus efeitos.

O presente contrato reger-se-á pelo direito português e será interpretado de acordo com a lei portuguesa, em conformidade com os contratos celebrados e executados neste país. Na falta de acordo amigável entre as partes, os respectivos conflitos e disputas de qualquer natureza relativos à formação, execução ou interpretação do presente Contrato, serão resolvidos definitivamente pelos Tribunais Judiciais, com expressa renúncia a quaisquer outros.

O utilizador poderá contactar-nos para qualquer questão relacionada com os Termos e Condições ou para qualquer questão relacionada com a utilização do website, através de carta registada com aviso de recepção para a morada Rua de São Martinho, nº 8, Lourinha de Baixo, 3450-092 Marmeleira ou através de e-mail.`,
};
