import React from "react";
import { UserDispatchContext } from "../";
import { tGuest } from "../../../models/guest";

const useUserDispatch = () => {
  const dispatch = React.useContext(UserDispatchContext);

  if (!dispatch)
    throw Error("useUserDispatch must be used within UserStateContext");

  const setProfile = (profile: tGuest) => {
    dispatch({ type: "set data", data: { profile } });
  };

  return { setProfile };
};

export default useUserDispatch;
