import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import { toaster } from "rsuite";
import { nRequestStatus, tPutRequest } from "../../interfaces/apiCalls";
import { REQUEST_STATUS } from "../../utils/apiCall";
import { notification } from "../../utils/notifications";

export type tUsePutRequest<T extends Record<string, any>> = {
  data: T;
  error: string | null;
  status: nRequestStatus.tStatus;
  reject(error: string, toast?: boolean, data?: Partial<T>): void;
  resolve(data?: Partial<T>): void;
  pending(data?: T): void;
  isIdle: boolean;
  isLoading: boolean;
  isResolved: boolean;
  isRejected: boolean;
  isFinal: boolean;
};

function usePutRequest<T extends Record<string, any>>(
  {
    status = REQUEST_STATUS.IDLE,
    error = null,
    initialData,
  }: {
    status?: nRequestStatus.tStatus;
    error?: string | null;
    initialData?: T;
  } = { status: REQUEST_STATUS.IDLE, error: null }
): tUsePutRequest<T> {
  const [state, setState] = useState<tPutRequest>({
    status,
    error,
    data: cloneDeep(initialData || {}),
  });

  const reject = useCallback(
    (error: string, toast: boolean = false, data: Partial<T> = {}) => {
      if (toast && error) {
        toaster.push(notification("error", error));
      }
      setState((prev) => ({
        ...prev,
        error,
        status: REQUEST_STATUS.REJECTED,
        data: { ...prev.data, ...data },
      }));
    },
    []
  );
  const resolve = useCallback((data: Partial<T> = {}) => {
    setState((prev) => ({
      ...prev,
      status: REQUEST_STATUS.RESOLVED,
      data: { ...prev.data, ...data },
    }));
  }, []);
  const pending = useCallback((data: Partial<T> = {}) => {
    setState((prev) => ({
      ...prev,
      status: REQUEST_STATUS.PENDING,
      data: { ...prev.data, ...data },
    }));
  }, []);

  const isIdle = state.status === REQUEST_STATUS.IDLE;
  const isLoading = state.status === REQUEST_STATUS.PENDING;
  const isResolved = state.status === REQUEST_STATUS.RESOLVED;
  const isRejected = state.status === REQUEST_STATUS.REJECTED;
  const isFinal = isRejected || isResolved;

  return {
    data: state.data,
    error: state.error,
    status: state.status,
    reject,
    resolve,
    pending,
    isIdle,
    isLoading,
    isResolved,
    isRejected,
    isFinal,
  };
}

export default usePutRequest;
