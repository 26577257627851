import React from "react";
import { Outlet } from "react-router-dom";
import Flex from "../../../components/Flex";
import { COLORS } from "../../../utils/colors";

const NoAuthRoot: React.FC = () => {
  return (
    <Flex column middle id="no-auth-root-container" style={{ height: "100%" }}>
      <Flex
        center
        style={{
          height: "100%",
          maxWidth: "500px",
          width: "100%",
          position: "relative",
          overflow: "auto",
          backgroundColor: COLORS.gray_100,
        }}
      >
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default NoAuthRoot;
