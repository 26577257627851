import axios from "axios";
import { max, min } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel, IconButton, Input, InputGroup } from "rsuite";
import { ReactComponent as AchievementAmico } from "../../../assets/amicos/Achievement-amico 2.svg";
import { ReactComponent as DataAmico } from "../../../assets/amicos/Data-amico 1.svg";
import { ReactComponent as HelloAmico } from "../../../assets/amicos/Hello-amico 1.svg";
import { ReactComponent as TripAmico } from "../../../assets/amicos/Trip-amico 1.svg";
import { ReactComponent as AccountCircleIcon } from "../../../assets/icons/account_circle.svg";
import { ReactComponent as ArrowBackIcon } from "../../../assets/icons/arrow_back.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/icons/visibility_off.svg";
import { ReactComponent as SignatureLogotypeIcon } from "../../../assets/signature-logotype.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PrivacyNoticeModal from "../../../components/Modals/PrivacyNoticeModal";
import TermsOfServiceModal from "../../../components/Modals/TermsOfServiceModal";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { UserContextProvider } from "../../../context/User";
import useUserDispatch from "../../../context/User/hooks/useUserDispatch";
import WithContextProvider from "../../../hoc/withProvider";
import useApiRequest from "../../../hooks/apiRequests/useApiRequest";
import usePatchRequest from "../../../hooks/apiRequests/usePatchRequest";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses";
import { preventDefault } from "../../../utils/others";

interface iOnboardingProps {
  onContinue(): void;
}

const Onboarding: React.FC<iOnboardingProps> = ({ onContinue }) => {
  const { trans } = useLocalizationState();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div style={{ position: "relative" }}>
      <Flex
        style={{
          position: "absolute",
          zIndex: 1,
          height: "100%",
          left: 4,
          ...(activeIndex === 0 ? { display: "none" } : {}),
        }}
        center
        middle
      >
        <IconButton
          disabled={activeIndex === 0}
          size="xs"
          circle
          icon={
            <Icon Element={ArrowBackIcon} size={20} fill={COLORS.secondary} />
          }
          onClick={() => setActiveIndex((prev) => max([0, prev - 1]) as number)}
        />
      </Flex>
      <Flex
        style={{
          position: "absolute",
          zIndex: 1,
          height: "100%",
          right: 4,
          ...(activeIndex === 3 ? { display: "none" } : {}),
        }}
        center
        middle
      >
        <IconButton
          disabled={activeIndex === 3}
          size="xs"
          circle
          icon={
            <Icon
              Element={ArrowForwardIcon}
              size={20}
              fill={COLORS.secondary}
            />
          }
          onClick={() => setActiveIndex((prev) => min([3, prev + 1]) as number)}
        />
      </Flex>
      <Carousel
        style={{ backgroundColor: COLORS.app_background, height: "99%" }}
        onSelect={setActiveIndex}
        activeIndex={activeIndex}
        shape="bar"
      >
        <Flex column middle>
          <Icon Element={HelloAmico} size={276} style={{ marginTop: "50px" }} />
          <Flex
            column
            middle
            gap={24}
            style={{ margin: "0 40px", marginTop: "50px" }}
          >
            <InterTag
              bold
              text={trans("Welcome")}
              size={40}
              color={COLORS.secondary}
            />
            <InterTag
              style={{ textAlign: "center" }}
              size={16}
              color={COLORS.secondary}
              wrap
              text={trans(
                "Monitor and optimize your water and energy usage with noytrall. Together, let's create a greener future during your stay."
              )}
            />
          </Flex>
        </Flex>

        <Flex column middle>
          <Icon Element={TripAmico} size={276} style={{ marginTop: "50px" }} />
          <Flex
            column
            middle
            gap={24}
            style={{ margin: "0 40px", marginTop: "50px" }}
          >
            <InterTag
              bold
              text={trans("Choose responsibly")}
              size={40}
              color={COLORS.secondary}
            />
            <InterTag
              style={{ textAlign: "center" }}
              size={16}
              color={COLORS.secondary}
              wrap
              text={trans(
                "Discover hotels commited to sustainability. Find eco-friendly accommodations that align with your values and make a positive impact."
              )}
            />
          </Flex>
        </Flex>

        <Flex column middle>
          <Icon Element={DataAmico} size={276} style={{ marginTop: "50px" }} />
          <Flex
            column
            middle
            gap={24}
            style={{ margin: "0 40px", marginTop: "50px" }}
          >
            <InterTag
              bold
              text={trans("Control your impact")}
              size={40}
              color={COLORS.secondary}
            />
            <InterTag
              style={{ textAlign: "center" }}
              size={16}
              color={COLORS.secondary}
              wrap
              text={trans(
                "Track your water and energy consumption in real-time. Visualize your usage patterns and take control of your environmental impact."
              )}
            />
          </Flex>
        </Flex>

        <Flex column middle>
          <Icon
            Element={AchievementAmico}
            size={276}
            style={{ marginTop: "50px" }}
          />
          <Flex
            column
            middle
            gap={24}
            style={{ margin: "0 40px", marginTop: "50px" }}
          >
            <InterTag
              bold
              text={trans("Play for sustainability")}
              size={40}
              color={COLORS.secondary}
            />
            <InterTag
              style={{ textAlign: "center" }}
              size={16}
              color={COLORS.secondary}
              wrap
              text={trans(
                "Engage in sustainability challenges, earn rewards, and compete with others. Join the movement for a greener world, one stay at a time."
              )}
            />
          </Flex>
          <SimpleButton
            text={{
              text: trans("Let's get started").toUpperCase(),
              size: 16,
              color: COLORS.white,
            }}
            appearance="primary"
            style={{ marginTop: "20px", width: "80%" }}
            onClick={onContinue}
          />
        </Flex>
      </Carousel>
    </div>
  );
};

const Register: React.FC = () => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const { loginUser } = useAuthDispatch();
  const { setProfile } = useUserDispatch();
  const [termsModalState, setTermsModalState] = useState<{ open: boolean }>({
    open: false,
  });
  const [privacyModalState, setPrivacyModalState] = useState<{ open: boolean }>(
    { open: false }
  );
  const [emailState, setEmailState] = useState({ value: "", error: "" });
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: "",
    visible: false,
  });
  const [password2State, setPassword2State] = useState({
    value: "",
    error: "",
    visible: false,
  });
  const [step, setStep] = useState(0);

  const [token, setToken] = useState("");

  const emailRequest = useApiRequest();
  const passwordRequest = usePatchRequest();
  const infoRequest = usePatchRequest();

  useEffect(() => {
    const handleClickTerms = () => setTermsModalState({ open: true });
    const handleClickPrivacy = () => setPrivacyModalState({ open: true });

    // Add event listener to the span after the component mounts
    const spanTerms = document.getElementById("signup-terms");
    if (spanTerms) {
      spanTerms.addEventListener("click", handleClickTerms);
    }
    // Add event listener to the span after the component mounts
    const spanPrivacy = document.getElementById("signup-privacy");
    if (spanPrivacy) {
      spanPrivacy.addEventListener("click", handleClickPrivacy);
    }

    // Cleanup event listener when the component unmounts
    return () => {
      if (spanTerms) {
        spanTerms.removeEventListener("click", handleClickTerms);
      }
      if (spanPrivacy) {
        spanPrivacy.removeEventListener("click", handleClickPrivacy);
      }
    };
  }, []);

  const handleVerifyEmail = () => {
    if (!emailState.value) {
      return setEmailState((prev) => ({
        ...prev,
        error: trans("Enter a valid email"),
      }));
    }
    emailRequest.pending();
    axios
      .get("/guest-app/v2/users/verify-email-available", {
        params: { email: emailState.value },
      })
      .then((res) => {
        const {
          data: { token, profile },
        } = res;
        emailRequest.resolve();
        setProfile(profile);
        setToken(token);
        setStep(1);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        emailRequest.reject(error, true);
      });
  };

  const handleCreatePassword = () => {
    passwordRequest.pending();
    axios.defaults.headers["token"] = token;
    axios
      .patch(`/guest-app/v2/users/user/change-password`, {
        oldPassword: "",
        newPassword: passwordState.value,
        newPassword2: password2State.value,
      })
      .then((res) => {
        const {
          data: { profile },
        } = res;
        passwordRequest.resolve();
        setProfile(profile);
        setStep(2);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        passwordRequest.reject(error, true);
      });
  };

  const handleSetUserInfo = () => {
    infoRequest.pending();
    axios.defaults.headers["token"] = token;
    axios
      .patch(`/guest-app/v1/users/user`, { name, username })
      .then((res) => {
        const {
          data: { profile },
        } = res;
        infoRequest.resolve();
        setStep(3);
        setProfile(profile);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        infoRequest.reject(error, true);
      });
  };

  const renderEmailStep = () => {
    return (
      <Flex
        column
        gap={64}
        style={{ padding: "16px 16px 0 16px", width: "100%" }}
      >
        <Flex center>
          <Icon width={214} height={64} Element={SignatureLogotypeIcon} />
        </Flex>
        <Flex column gap={40}>
          <form onSubmit={preventDefault(handleVerifyEmail)}>
            <Flex column gap={24}>
              <InterTag
                text={trans("Sign up")}
                size={40}
                color={COLORS.secondary}
                bold
              />
              <InputWrapper label={trans("Email")} error={emailState.error}>
                <InputGroup>
                  <InputGroup.Addon className={`bg-white`}>
                    <Icon
                      Element={MailIcon}
                      width={24}
                      height={24}
                      fill={COLORS.gray_400}
                    />
                  </InputGroup.Addon>
                  <Input
                    value={emailState.value}
                    onChange={(value) =>
                      setEmailState((prev) => ({ ...prev, error: "", value }))
                    }
                    type="text"
                    placeholder={trans("user@youremail.com")}
                    size="lg"
                  />
                </InputGroup>
              </InputWrapper>
              <SimpleButton
                loading={emailRequest.isLoading}
                appearance="primary"
                text={{
                  text: trans("Continue").toUpperCase(),
                  size: 14,
                  color: COLORS.white,
                }}
                onClick={handleVerifyEmail}
              />
              <InterTag
                wrap
                style={{ textAlign: "center" }}
                text={trans(
                  `By signing up you are agreeing to our <span id="signup-terms" class="hover-underline-primary-900" style="color: var(--color-primary); cursor: pointer;" >Terms and Conditions</span> and <span id="signup-privacy" class="hover-underline-primary-900" style="color: var(--color-primary); cursor: pointer;" >Privacy Policy</span>`
                )}
                asHTML
              />
            </Flex>
            <input type="submit" style={{ display: "none" }} />
          </form>
          <Flex center>
            <Flex middle column gap={4}>
              <InterTag
                text={trans("Not new to noytrall?")}
                color={COLORS.secondary}
                size={14}
              />
              <Flex middle row gap={4}>
                <SimpleButton
                  loading={infoRequest.isLoading}
                  style={{ padding: 0 }}
                  appearance="link"
                  text={{
                    text: trans("Login"),
                    size: 14,
                    color: COLORS.primary,
                  }}
                  onClick={() => navigate("/login")}
                />
                <InterTag size={14} text={trans("or")} />
                <SimpleButton
                  style={{ padding: 0 }}
                  appearance="link"
                  text={{
                    text: trans("Login as a Guest"),
                    size: 14,
                    color: COLORS.primary,
                  }}
                  onClick={() => navigate("/login-guest")}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderPasswordsStep = () => {
    return (
      <Flex column style={{ padding: "16px 16px 0 16px", width: "100%" }}>
        <Flex one column center>
          <form onSubmit={preventDefault(handleCreatePassword)}>
            <Flex column gap={24}>
              <InterTag
                text={trans("Create password")}
                size={40}
                color={COLORS.secondary}
                bold
              />
              <Flex column>
                <InterTag
                  style={{ textWrap: "wrap" }}
                  text={trans("Please create your password")}
                  size={16}
                  color={COLORS.secondary}
                />
                <InterTag
                  style={{ textWrap: "wrap" }}
                  text={trans(
                    "This will be your access to noytrall moving on."
                  )}
                  size={16}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex column gap={8}>
                <InputWrapper label={trans("Password")}>
                  <InputGroup>
                    <InputGroup.Addon className={`bg-white`}>
                      <Icon
                        Element={PasswordIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray_400}
                      />
                    </InputGroup.Addon>
                    <Input
                      value={passwordState.value}
                      onChange={(value) =>
                        setPasswordState((prev) => ({ ...prev, value }))
                      }
                      type={passwordState.visible ? "text" : "password"}
                      placeholder={trans("New password")}
                      size="lg"
                    />
                    <InputGroup.Button
                      className={`hover-bg-gray-100 bg-white`}
                      onClick={() =>
                        setPasswordState((prev) => ({
                          ...prev,
                          visible: !prev.visible,
                        }))
                      }
                    >
                      {passwordState.visible ? (
                        <Icon
                          Element={VisibilityIcon}
                          width={24}
                          height={24}
                          fill={COLORS.gray}
                        />
                      ) : (
                        <Icon
                          Element={VisibilityOffIcon}
                          width={24}
                          height={24}
                          fill={COLORS.gray}
                        />
                      )}
                    </InputGroup.Button>
                  </InputGroup>
                </InputWrapper>
                <InputWrapper label={trans("Confirm Password")}>
                  <InputGroup>
                    <InputGroup.Addon className={`bg-white`}>
                      <Icon
                        Element={PasswordIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray_400}
                      />
                    </InputGroup.Addon>
                    <Input
                      value={password2State.value}
                      onChange={(value) =>
                        setPassword2State((prev) => ({ ...prev, value }))
                      }
                      type={password2State.visible ? "text" : "password"}
                      placeholder={trans("Confirm new password")}
                      size="lg"
                    />
                    <InputGroup.Button
                      className={`hover-bg-gray-100 bg-white`}
                      onClick={() =>
                        setPassword2State((prev) => ({
                          ...prev,
                          visible: !prev.visible,
                        }))
                      }
                    >
                      {password2State.visible ? (
                        <Icon
                          Element={VisibilityIcon}
                          width={24}
                          height={24}
                          fill={COLORS.gray}
                        />
                      ) : (
                        <Icon
                          Element={VisibilityOffIcon}
                          width={24}
                          height={24}
                          fill={COLORS.gray}
                        />
                      )}
                    </InputGroup.Button>
                  </InputGroup>
                </InputWrapper>
              </Flex>
              <SimpleButton
                loading={passwordRequest.isLoading}
                appearance="primary"
                text={{
                  text: trans("Continue").toUpperCase(),
                  size: 14,
                  color: COLORS.white,
                }}
                onClick={handleCreatePassword}
              />
            </Flex>
            <input type="submit" style={{ display: "none" }} />
          </form>
        </Flex>
      </Flex>
    );
  };

  const renderAccoutInfoStep = () => {
    return (
      <Flex column style={{ padding: "16px 16px 0 16px", width: "100%" }}>
        <Flex row left>
          <IconButton
            onClick={() => setStep(1)}
            appearance="subtle"
            circle
            size="xs"
            icon={
              <Icon Element={ArrowBackIcon} fill={COLORS.secondary} size={24} />
            }
          />
        </Flex>
        <Flex one column center>
          <form onSubmit={preventDefault(handleSetUserInfo)}>
            <Flex column gap={24}>
              <InterTag
                text={trans("Create account")}
                size={40}
                color={COLORS.secondary}
                bold
              />
              <Flex column>
                <InterTag
                  style={{ textWrap: "wrap" }}
                  text={trans("Let's get to know you better.")}
                  size={16}
                  color={COLORS.secondary}
                />
                <InterTag
                  style={{ textWrap: "wrap" }}
                  text={trans(
                    "This information will help us personalize your noytrall experience and connect you with sustainability initiatives in your region."
                  )}
                  size={16}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex column gap={8}>
                <InputWrapper label={trans("Full name")}>
                  <InputGroup>
                    <InputGroup.Addon className={`bg-white`}>
                      <Icon
                        Element={AccountCircleIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray_400}
                      />
                    </InputGroup.Addon>
                    <Input
                      value={name}
                      onChange={setName}
                      type="text"
                      placeholder={trans("Full Name")}
                      size="lg"
                    />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper label={trans("Username")}>
                  <InputGroup>
                    <InputGroup.Addon className={`bg-white`}>
                      <Icon
                        Element={AccountCircleIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray_400}
                      />
                    </InputGroup.Addon>
                    <Input
                      value={username}
                      onChange={setUsername}
                      type="text"
                      placeholder={trans("username")}
                      size="lg"
                    />
                  </InputGroup>
                </InputWrapper>
              </Flex>
              <SimpleButton
                loading={infoRequest.isLoading}
                appearance="primary"
                text={{
                  text: trans("Continue").toUpperCase(),
                  size: 14,
                  color: COLORS.white,
                }}
                onClick={handleSetUserInfo}
              />
            </Flex>
            <input type="submit" style={{ display: "none" }} />
          </form>
        </Flex>
      </Flex>
    );
  };

  const handleGoToApp = () => {
    loginUser(token);
  };

  const renderOnboardingStep = () => {
    return <Onboarding onContinue={handleGoToApp} />;
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return renderEmailStep();

      case 1:
        return renderPasswordsStep();

      case 2:
        return renderAccoutInfoStep();

      case 3:
        return renderOnboardingStep();

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <TermsOfServiceModal
        open={termsModalState.open}
        onClose={() => setTermsModalState({ open: false })}
        language="en"
      />
      <PrivacyNoticeModal
        open={privacyModalState.open}
        onClose={() => setPrivacyModalState({ open: false })}
        language="en"
      />
      {renderStep()}
    </Fragment>
  );
};

export default WithContextProvider(UserContextProvider, Register);
