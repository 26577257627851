import React, { useState } from "react";
import { IconButton, Input, InputGroup, Modal, ModalProps } from "rsuite";
import { ReactComponent as ArrowBackIcon } from "../../../../../assets/icons/arrow_back.svg";
import { ReactComponent as VisibilityIcon } from "../../../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../../../assets/icons/visibility_off.svg";
import Flex from "../../../../../components/Flex";
import Icon from "../../../../../components/Icon";
import InputWrapper from "../../../../../components/InputWrapper";
import InterTag from "../../../../../components/Text/Inter";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../../../utils/colors";
import { DEFAULT_MODAL_PROPS } from "../../../../../utils/rsuite/modals";
import SimpleButton from "../../../../../components/RsuiteWrapper/SimpleButton";
import usePutRequest from "../../../../../hooks/apiRequests/usePutRequest";
import axios from "axios";
import { getErrorMessage } from "../../../../../utils/httpResponses";
import useSimpleToaster from "../../../../../hooks/useSimpleToaster";

interface iPasswordModalWrappedProps extends Pick<iProps, "onClose"> {}

const PasswordModalWrapped: React.FC<iPasswordModalWrappedProps> = ({
  onClose,
}) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const request = usePutRequest();

  const [oldPasswordState, setOldPasswordState] = useState({
    value: "",
    error: "",
    visible: false,
  });
  const [newPasswordState, setNewPasswordState] = useState({
    value: "",
    error: "",
    visible: false,
  });
  const [newPassword2State, setNewPassword2State] = useState({
    value: "",
    error: "",
    visible: false,
  });

  const renderPasswordInput = (
    label: string,
    state: { value: string; error: string; visible: boolean },
    setState: React.Dispatch<
      React.SetStateAction<{
        value: string;
        error: string;
        visible: boolean;
      }>
    >
  ) => {
    return (
      <div>
        <InputWrapper label={label}>
          <InputGroup>
            <Input
              disabled={request.isLoading}
              value={state.value}
              onChange={(value) => setState((prev) => ({ ...prev, value }))}
              type={state.visible ? "text" : "password"}
              placeholder={trans("MySecureP@ssw0rd")}
              size="md"
            />
            <InputGroup.Button
              className={`hover-bg-gray-100 bg-white`}
              onClick={() =>
                setState((prev) => ({ ...prev, visible: !prev.visible }))
              }
            >
              {state.visible ? (
                <Icon
                  Element={VisibilityIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              ) : (
                <Icon
                  Element={VisibilityOffIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              )}
            </InputGroup.Button>
          </InputGroup>
        </InputWrapper>
      </div>
    );
  };

  const canSubmit = () => {
    return (
      oldPasswordState.value.length > 0 &&
      newPassword2State.value.length > 0 &&
      newPassword2State.value === newPasswordState.value
    );
  };

  const handleConfirm = () => {
    if (!canSubmit()) return;
    request.pending();
    axios
      .put(`/guest-app/v2/users/user/change-password`, {
        oldPassword: oldPasswordState.value,
        newPassword: newPasswordState.value,
        newPassword2: newPassword2State.value,
      })
      .then((res) => {
        request.resolve();
        toaster.success(trans("Password updated"));
        onClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        request.reject(error);
      });
  };

  return (
    <Modal.Body style={{ padding: 0, height: "100%", margin: 0 }}>
      <Flex column middle style={{ height: "100%" }}>
        <Flex
          column
          middle
          style={{
            height: "100%",
            maxWidth: "500px",
            width: "100%",
            position: "relative",
            overflow: "auto",
            backgroundColor: COLORS.gray_100,
          }}
        >
          <div
            style={{
              position: "fixed",
              zIndex: 3,
              top: 0,
              width: "100%",
              maxWidth: "500px",
              backgroundColor: COLORS.white,
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Flex
              color={COLORS.white}
              row
              gap={24}
              middle
              style={{ padding: "8px" }}
            >
              <IconButton
                onClick={onClose}
                circle
                size="xs"
                appearance="subtle"
                icon={
                  <Icon
                    Element={ArrowBackIcon}
                    size={24}
                    fill={COLORS.secondary}
                  />
                }
              />
              <InterTag
                size={20}
                color={COLORS.secondary}
                text={trans("Password")}
                bold
              />
            </Flex>
          </div>
          <form
            style={{ width: "100%", height: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirm();
            }}
          >
            <Flex
              column
              between
              style={{ marginTop: "70px", width: "100%", height: "80%" }}
            >
              <Flex column gap={24} style={{ padding: "16px" }}>
                {renderPasswordInput(
                  trans("Old Password"),
                  oldPasswordState,
                  setOldPasswordState
                )}
                {renderPasswordInput(
                  trans("New Password"),
                  newPasswordState,
                  setNewPasswordState
                )}
                {renderPasswordInput(
                  trans("Confirm Password"),
                  newPassword2State,
                  setNewPassword2State
                )}
              </Flex>
              <Flex style={{ padding: "0 16px" }}>
                <SimpleButton
                  disabled={!canSubmit()}
                  loading={request.isLoading}
                  onClick={handleConfirm}
                  style={{ width: "100%" }}
                  text={{ text: trans("Save"), color: COLORS.white }}
                  appearance="primary"
                />
              </Flex>
            </Flex>
            <input type="submit" style={{ display: "none" }} />
          </form>
        </Flex>
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
}

const PasswordModal: React.FC<iProps> = ({ onClose, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...props }}>
      <PasswordModalWrapped {...{ onClose }} />
    </Modal>
  );
};

export default PasswordModal;
