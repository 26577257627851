import { cloneDeep } from "lodash";
import React, { useEffect, useReducer } from "react";
import { defaultUser } from "../../utils/guests";
import { nUser } from "./interfaces";

const UserDispatchContext = React.createContext<
  nUser.tDispatchContext | undefined
>(undefined);
UserDispatchContext.displayName = "UserDispatchContext";
const UserStateContext = React.createContext<nUser.tStateContext | undefined>(
  undefined
);
UserStateContext.displayName = "UserStateContext";

const LS_DATA = "__noytrall-ga-u__";

const initialState: nUser.tState = {
  data: {
    profile: cloneDeep(defaultUser),
  },
  status: "idle",
  error: null,
};

const reducer = (state: nUser.tState, action: nUser.tAction): nUser.tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const UserContextProvider: React.FC<nUser.iContextProps> = ({ children }) => {
  const [state, dispatch]: [nUser.tState, React.Dispatch<nUser.tAction>] =
    useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "pending" });

    const stored = localStorage.getItem(LS_DATA);

    if (!stored) {
      return dispatch({ type: "rejected", error: "Not in storage" });
    }
    const data = JSON.parse(stored) as nUser.tStateData;
    return dispatch({ type: "set data", data });
  }, []);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export { UserContextProvider, UserDispatchContext, UserStateContext };
