import React, { forwardRef } from "react";
import { Button, ButtonProps } from "rsuite";
import InterTag, { iInterTagProps } from "../../Text/Inter";
import { toTextProps } from "../../Text/utils";

interface iProps extends ButtonProps {
  text: string | iInterTagProps;
}

const SimpleButton = forwardRef<HTMLButtonElement, iProps>(
  ({ text, ...props }, ref) => {
    return (
      <Button {...{ ...props, ref }}>
        <InterTag
          {...{ ...toTextProps(text), ...(props.loading ? { color: "" } : {}) }}
        />
      </Button>
    );
  }
);

export default SimpleButton;
