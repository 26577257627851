import React from "react";
import { GuestStateContext } from "..";
import { REQUEST_STATUS } from "../../../utils/apiCall";

const useGuestState = () => {
  const state = React.useContext(GuestStateContext);

  if (!state)
    throw Error("useGuestState must be used within GuestStateContext");

  const {
    data: { stay, hotel },
    status,
  } = state;

  return {
    stay,
    hotel,
    isLoading: status === REQUEST_STATUS.PENDING,
    isResolved: status === REQUEST_STATUS.RESOLVED,
    isRejected: status === REQUEST_STATUS.REJECTED,
  };
};

export default useGuestState;
