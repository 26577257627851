import React, { Fragment, useState } from "react";
import Flex from "../../../../components/Flex";
import { COLORS } from "../../../../utils/colors";
import { ReactComponent as MenuIcon } from "../../../../assets/icons/menu.svg";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { IconButton } from "rsuite";
import Icon from "../../../../components/Icon";
import InterTag from "../../../../components/Text/Inter";
import useUserState from "../../../../context/User/hooks/useUserState";
import moment from "moment";
import Menu from "./menu";

const Profile: React.FC = () => {
  const { trans } = useLocalizationState();
  const { profile } = useUserState();
  const [menuState, setMenuState] = useState<{ open: boolean }>({
    open: false,
  });

  return (
    <Fragment>
      <Menu
        open={menuState.open}
        onClose={() => setMenuState({ open: false })}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 3,
          top: 0,
          width: "100%",
          maxWidth: "500px",
          backgroundColor: COLORS.gray_100,
        }}
      >
        <Flex right style={{ padding: "32px 16px" }}>
          <IconButton
            onClick={() => setMenuState({ open: true })}
            appearance="subtle"
            circle
            size="xs"
            icon={<Icon size={24} fill={COLORS.secondary} Element={MenuIcon} />}
          />
        </Flex>
      </div>
      <Flex column style={{ marginTop: "100px", padding: "0 16px" }}>
        <Flex column gap={8}>
          <InterTag
            text={profile.name}
            size={32}
            color={COLORS.secondary}
            bold
          />
          <InterTag
            text={`@${profile.username}`}
            size={14}
            color={COLORS.gas}
          />
          <InterTag
            sizde={12}
            color={COLORS.gas}
            text={`${trans("Member since $0", {
              parameters: [moment(profile.createdAt).format("MMMM YYYY")],
            })}`}
          />
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default Profile;
