import { useMemo } from "react";
import { useToaster } from "rsuite";
import { notification } from "../utils/notifications";

const useSimpleToaster = () => {
  const toaster = useToaster();

  return useMemo(() => {
    const error = (message: string) =>
      toaster.push(notification("error", message));
    const success = (message: string) =>
      toaster.push(notification("success", message));
    const info = (message: string) =>
      toaster.push(notification("info", message));
    const warning = (message: string) =>
      toaster.push(notification("warning", message));
    return { error, success, info, warning };
  }, [toaster]);
};
export default useSimpleToaster;
