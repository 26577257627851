import { useState } from "react";
import { nRequestStatus, tApiRequest } from "../../interfaces/apiCalls";
import {
  REQUEST_STATUS,
  setGetRequestStateResolved,
  setRequestStatePending,
  setRequestStateRejected,
} from "../../utils/apiCall";

export type tUseApiRequest<T extends any = any> = {
  data: T;
  error: string | null;
  status: nRequestStatus.tStatus;
  reject(error: string, toast?: boolean): void;
  resolve(data?: T): void;
  pending(): void;
  isIdle: boolean;
  isLoading: boolean;
  isResolved: boolean;
  isRejected: boolean;
  isFinal: boolean;
};

function useApiRequest<T extends any = any>(
  {
    initialState,
    status = REQUEST_STATUS.IDLE,
    error = null,
  }: {
    initialState?: T;
    status?: nRequestStatus.tStatus;
    error?: string | null;
  } = { status: REQUEST_STATUS.IDLE, error: null }
): tUseApiRequest {
  const [state, setState] = useState<tApiRequest>({
    data: initialState,
    status,
    error,
  });

  const reject = (error: string, toast: boolean = false) => {
    setRequestStateRejected(setState, error, toast);
  };
  const resolve = (data?: T) => setGetRequestStateResolved(setState, data);
  const pending = () => setRequestStatePending(setState);

  const isIdle = state.status === REQUEST_STATUS.IDLE;
  const isLoading = state.status === REQUEST_STATUS.PENDING;
  const isResolved = state.status === REQUEST_STATUS.RESOLVED;
  const isRejected = state.status === REQUEST_STATUS.REJECTED;
  const isFinal = isRejected || isResolved;

  return {
    data: state.data,
    error: state.error,
    status: state.status,
    reject,
    resolve,
    pending,
    isIdle,
    isLoading,
    isResolved,
    isRejected,
    isFinal,
  };
}

export default useApiRequest;
