import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "rsuite";
import { ReactComponent as SignatureLogotypeIcon } from "../../../assets/signature-logotype.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses";
import { preventDefault } from "../../../utils/others";

const ForgotPassword: React.FC = () => {
  const toaster = useSimpleToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const request = usePostRequest();
  const [email, setEmail] = useState("");

  const handleConfirm = () => {
    request.pending();
    axios
      .post(`/guest-app/v2/users/forgot-password`, { email })
      .then((res) => {
        request.resolve();
        toaster.success(res.data.message);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        request.reject(error);
      });
  };

  return (
    <Flex
      column
      gap={64}
      style={{ padding: "16px 16px 0 16px", width: "100%" }}
    >
      <Flex center>
        <Icon width={214} height={64} Element={SignatureLogotypeIcon} />
      </Flex>

      <Flex column gap={24}>
        <form onSubmit={preventDefault(handleConfirm)}>
          <Flex center left column gap={24}>
            <InterTag
              text={trans("Forgot password")}
              size={40}
              color={COLORS.secondary}
              bold
            />
            <Flex column>
              <InterTag
                style={{ textWrap: "wrap" }}
                text={trans(
                  "Don't worry! Just enter the email address associated with your account. We will immediately send you a way to get you back in"
                )}
                size={16}
                color={COLORS.secondary}
              />
            </Flex>
            <Input
              disabled={request.isLoading}
              size="lg"
              value={email}
              onChange={setEmail}
              placeholder="user@domain.com"
              autoComplete="off"
            />
            <SimpleButton
              text={{
                text: trans("Continue").toUpperCase(),
                color: COLORS.white,
                size: 16,
              }}
              onClick={handleConfirm}
              appearance="primary"
              loading={request.isLoading}
            />
          </Flex>
          <input type="submit" style={{ display: "none" }} />
        </form>

        <Flex center>
          <Button appearance="link" onClick={() => navigate("/login")}>
            <InterTag
              size={14}
              color={COLORS.primary}
              text={trans("Login with your account")}
            />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
