import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "rsuite";
import { ReactComponent as SignatureLogotypeIcon } from "../../../assets/signature-logotype.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import { GuestContextProvider } from "../../../context/Guest";
import useGuestDispatch from "../../../context/Guest/hooks/useGuestDispatch";
import useGuestState from "../../../context/Guest/hooks/useGuestState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses";

const LoginAsGuest: React.FC = () => {
  const navigate = useNavigate();
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const [code, setCode] = useState("");
  const request = usePostRequest();
  const [tokenState, setTokenState] = useState<string | null>(null);
  const { setGuestData } = useGuestDispatch();
  const guestState = useGuestState();
  const { loginGuest } = useAuthDispatch();

  useEffect(() => {
    if (guestState.isResolved && tokenState) {
      loginGuest(tokenState);
    }
  }, [guestState.isResolved, loginGuest, tokenState]);

  const handleConfirm = () => {
    request.pending();
    axios
      .post(`/guest-app/v2/guests/login`, { code })
      .then((res) => {
        const {
          data: { stay, hotel, token },
        } = res;
        setTokenState(token);
        setGuestData(stay, hotel);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        request.reject(error);
      });
  };

  return (
    <Flex column gap={64} style={{ padding: "16px 16px 0 16px" }}>
      <Flex center>
        <Icon width={214} height={64} Element={SignatureLogotypeIcon} />
      </Flex>
      <Flex column gap={24}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirm();
          }}
        >
          <Flex center left column gap={24}>
            <InterTag
              text={trans("Login as a Guest")}
              size={40}
              color={COLORS.secondary}
              bold
            />
            <Flex column>
              <InterTag
                style={{ textWrap: "wrap" }}
                text={trans("Enter your <strong>noytrall stay ID</strong>.")}
                size={16}
                color={COLORS.secondary}
                asHTML
              />
              <InterTag
                style={{ textWrap: "wrap" }}
                text={trans(
                  "This ID is handed to you at the hotel's front desk and will be forwarded to your email address after your check-in."
                )}
                size={16}
                color={COLORS.secondary}
              />
            </Flex>
            <Input
              disabled={request.isLoading}
              size="lg"
              value={code}
              onChange={(value) => setCode(value.slice(0, 6).toUpperCase())}
              placeholder="HT5J3J"
              autoComplete="off"
            />
            <SimpleButton
              text={{
                text: trans("Login").toUpperCase(),
                color: COLORS.white,
                size: 16,
              }}
              onClick={handleConfirm}
              appearance="primary"
              loading={request.isLoading}
            />
          </Flex>
          <input type="submit" style={{ display: "none" }} />
        </form>

        <Flex center>
          <Button appearance="link" onClick={() => navigate("/login")}>
            <InterTag
              size={14}
              color={COLORS.primary}
              text={trans("Login with your account")}
            />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WithContextProvider(GuestContextProvider, LoginAsGuest);
