import React from "react";
import { AuthStateContext } from "../";
import { REQUEST_STATUS } from "../../../utils/apiCall";

const useAuthState = () => {
  const state = React.useContext(AuthStateContext);

  if (!state) throw Error("useAuthState must be used within AuthStateContext");

  const {
    data: { token, type },
    status,
  } = state;

  return { token, type, isResolved: status === REQUEST_STATUS.RESOLVED };
};

export default useAuthState;
