import axios from "axios";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import { IconButton, InlineEdit, Loader, Modal, ModalProps } from "rsuite";
import { ReactComponent as ArrowBackIcon } from "../../../../../assets/icons/arrow_back.svg";
import Flex from "../../../../../components/Flex";
import Icon from "../../../../../components/Icon";
import InputWrapper from "../../../../../components/InputWrapper";
import InterTag from "../../../../../components/Text/Inter";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import useUserState from "../../../../../context/User/hooks/useUserState";
import usePutRequest from "../../../../../hooks/apiRequests/usePutRequest";
import useSimpleToaster from "../../../../../hooks/useSimpleToaster";
import { COLORS } from "../../../../../utils/colors";
import { getErrorMessage } from "../../../../../utils/httpResponses";
import { DEFAULT_MODAL_PROPS } from "../../../../../utils/rsuite/modals";
import useUserDispatch from "../../../../../context/User/hooks/useUserDispatch";
import PasswordModal from "./password";

type tData = {
  name: string;
  username: string;
  email: string;
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    email: "",
    name: "",
    username: "",
  },
  errors: { email: "", name: "", username: "" },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iPersonalInformationModalWrappedProps
  extends Pick<iProps, "onClose"> {}

const PersonalInformationModalWrapped: React.FC<
  iPersonalInformationModalWrappedProps
> = ({ onClose }) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { profile } = useUserState();
  const { setProfile } = useUserDispatch();
  const [passwordModalState, setPasswordModalState] = useState<{
    open: boolean;
  }>({ open: false });

  const editRequest = usePutRequest<{ key: keyof tData | null }>({
    initialData: { key: null },
  });

  useEffect(() => {
    dispatch({
      type: "data",
      data: {
        name: profile.name,
        username: profile.username,
        email: profile.email,
      },
    });
  }, [profile]);

  const handleSave = (key: keyof tData) => {
    editRequest.pending({ key });
    axios
      .put(`/guest-app/v1/users/user`, { [key]: state.data[key] })
      .then((res) => {
        const {
          data: { profile },
        } = res;
        setProfile(profile);
        editRequest.resolve({ key: null });
        toaster.success(trans("Profile updated"));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        editRequest.reject(error, true, { key: null });
        handleCancel(key)();
      });
  };

  const handleChange = (key: keyof tData) => (value: string) =>
    dispatch({ type: "key value", key, value });
  const handleCancel = (key: keyof tData) => () =>
    dispatch({ type: "key value", key, value: profile[key] });

  const renderInlineEdit = (key: keyof tData, label: string) => {
    return (
      <InputWrapper label={label} error={state.errors[key]}>
        {/* @ts-ignore */}
        <InlineEdit
          disabled={editRequest.isLoading}
          style={{
            backgroundColor: COLORS.white,
            borderRadius: "6px",
            ...(editRequest.isLoading ? { opacity: 0.5 } : {}),
            width: "100%",
          }}
          value={state.data[key]}
          onChange={handleChange(key)}
          onCancel={handleCancel(key)}
          onSave={(event: any) => {
            if (
              event.type === "click" ||
              (event.type === "keydown" && event.key === "Enter")
            ) {
              if (state.data[key] !== profile[key]) handleSave(key);
              return;
            }
            return handleCancel(key)();
          }}
          {...{
            ...(editRequest.isLoading && key === editRequest.data.key
              ? {
                  children: (props, ref) => (
                    <Flex
                      {...{
                        ...props,
                        style: {
                          borderRadius: "6px",
                          height: "34px",
                          opacity: 0.5,
                          paddingLeft: "11px",
                        },
                        ref,
                      }}
                      color={COLORS.white}
                      middle
                    >
                      <Loader size="xs" />
                    </Flex>
                  ),
                }
              : {}),
          }}
        />
      </InputWrapper>
    );
  };

  return (
    <Fragment>
      <PasswordModal
        open={passwordModalState.open}
        onClose={() => {
          setPasswordModalState({ open: false });
        }}
      />
      <Modal.Body style={{ padding: 0, height: "100%", margin: 0 }}>
        <Flex column middle style={{ height: "100%" }}>
          <Flex
            column
            style={{
              height: "100%",
              maxWidth: "500px",
              width: "100%",
              position: "relative",
              overflow: "auto",
              backgroundColor: COLORS.gray_100,
            }}
          >
            <div
              style={{
                position: "fixed",
                zIndex: 3,
                top: 0,
                width: "100%",
                maxWidth: "500px",
                backgroundColor: COLORS.white,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Flex
                color={COLORS.white}
                row
                gap={24}
                middle
                style={{ padding: "8px" }}
              >
                <IconButton
                  onClick={onClose}
                  circle
                  size="xs"
                  appearance="subtle"
                  icon={
                    <Icon
                      Element={ArrowBackIcon}
                      size={24}
                      fill={COLORS.secondary}
                    />
                  }
                />
                <InterTag
                  size={20}
                  color={COLORS.secondary}
                  text={trans("Personal Information")}
                  bold
                />
              </Flex>
            </div>
            <Flex
              column
              gap={24}
              style={{ padding: "16px", marginTop: "70px" }}
            >
              {renderInlineEdit("name", trans("Name"))}
              {renderInlineEdit("username", trans("Username"))}
              <InputWrapper label={trans("Password")}>
                <Flex
                  className="hover-bg-gray-200"
                  color={COLORS.white}
                  style={{
                    borderRadius: "8px",
                    height: "34px",
                    paddingLeft: "11px",
                    cursor: "pointer",
                  }}
                  onClick={() => setPasswordModalState({ open: true })}
                  middle
                >
                  <InterTag
                    size={14}
                    color={COLORS.secondary}
                    text={"********"}
                  />
                </Flex>
              </InputWrapper>
              {renderInlineEdit("email", trans("Email"))}
            </Flex>
          </Flex>
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
}

const PersonalInformationModal: React.FC<iProps> = ({ onClose, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...props }}>
      <PersonalInformationModalWrapped {...{ onClose }} />
    </Modal>
  );
};

export default PersonalInformationModal;
