import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputGroup } from "rsuite";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/icons/visibility_off.svg";
import { ReactComponent as SignatureLogotypeIcon } from "../../../assets/signature-logotype.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useParseSearchParams from "../../../hooks/useParseSearchParams";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses";
import { preventDefault } from "../../../utils/others";

interface iProps {}

const ResetPassword: React.FC<iProps> = () => {
  const toaster = useSimpleToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  const [passwordState, setPasswordState] = useState({
    value: "",
    error: "",
    visible: false,
  });
  const [password2State, setPassword2State] = useState({
    value: "",
    error: "",
    visible: false,
  });

  const searchParams = useParseSearchParams();
  const request = usePostRequest();

  const renderPasswordInput = (
    label: string,
    state: { value: string; error: string; visible: boolean },
    setState: React.Dispatch<
      React.SetStateAction<{
        value: string;
        error: string;
        visible: boolean;
      }>
    >
  ) => {
    return (
      <div>
        <InputWrapper label={label}>
          <InputGroup>
            <Input
              disabled={request.isLoading}
              value={state.value}
              onChange={(value) => setState((prev) => ({ ...prev, value }))}
              type={state.visible ? "text" : "password"}
              placeholder={trans("MySecureP@ssw0rd")}
              size="md"
            />
            <InputGroup.Button
              className={`hover-bg-gray-100 bg-white`}
              onClick={() =>
                setState((prev) => ({ ...prev, visible: !prev.visible }))
              }
            >
              {state.visible ? (
                <Icon
                  Element={VisibilityIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              ) : (
                <Icon
                  Element={VisibilityOffIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              )}
            </InputGroup.Button>
          </InputGroup>
        </InputWrapper>
      </div>
    );
  };

  const handleConfirm = () => {
    if (!searchParams.token) {
      return toaster.error(
        trans("Cannot find token, reload tab or open with link again.")
      );
    }

    request.pending();
    axios
      .post("/guest-app/v2/users/reset-password", {
        password: passwordState.value,
        token: searchParams.token,
      })
      .then((res) => {
        toaster.success(trans("Password changed"));
        request.resolve();
        navigate("/login");
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error, true);
      });
  };

  return (
    <Flex
      column
      gap={64}
      style={{ padding: "16px 16px 0 16px", width: "100%" }}
    >
      <Flex center>
        <Icon width={214} height={64} Element={SignatureLogotypeIcon} />
      </Flex>
      <form onSubmit={preventDefault(handleConfirm)}>
        <Flex column gap={24}>
          <Flex center left column gap={24}>
            <InterTag
              text={trans("Reset password")}
              size={40}
              color={COLORS.secondary}
              bold
            />
            <InterTag
              style={{ textWrap: "wrap" }}
              text={trans(
                "Please redefine your new password. This will be your new access to noytrall moving on."
              )}
              size={16}
              color={COLORS.secondary}
            />
            <Flex column gap={8}>
              {renderPasswordInput(
                trans("New Password"),
                passwordState,
                setPasswordState
              )}
              {renderPasswordInput(
                trans("Confirm new Password"),
                password2State,
                setPassword2State
              )}
            </Flex>
            <SimpleButton
              text={{
                text: trans("Continue").toUpperCase(),
                color: COLORS.white,
                size: 16,
              }}
              onClick={handleConfirm}
              appearance="primary"
              loading={request.isLoading}
            />
            <input type="submit" style={{ display: "none" }} />
            <Flex center>
              <Button appearance="link" onClick={() => navigate("/login")}>
                <InterTag
                  size={14}
                  color={COLORS.primary}
                  text={trans("Login with your account")}
                />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};

export default ResetPassword;
