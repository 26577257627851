import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/Auth";
import { LocalizationContextProvider } from "./context/Localization";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <LocalizationContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </LocalizationContextProvider>
  </React.StrictMode>
);
