import React, { useEffect, useRef, useState } from "react";
import { IconButton, Input, Modal, ModalProps } from "rsuite";
import { ReactComponent as ArrowBackIcon } from "../../../assets/icons/arrow_back.svg";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import Flex from "../../Flex";
import Icon from "../../Icon";
import SimpleButton from "../../RsuiteWrapper/SimpleButton";
import InterTag from "../../Text/Inter";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import axios from "axios";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { getErrorMessage } from "../../../utils/httpResponses";

interface iAddCodeModalWrappedProps extends Pick<iProps, "onClose"> {}

const AddCodeModalWrapped: React.FC<iAddCodeModalWrappedProps> = ({
  onClose,
}) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const [codeState, setCodeState] = useState({ value: "", error: "" });
  const request = usePostRequest();
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleConfirm = () => {
    if (!codeState.value) {
      return setCodeState((prev) => ({
        ...prev,
        error: trans("Enter a code"),
      }));
    }
    request.pending();
    axios
      .put(`/guest-app/v1/stays/add`, { code: codeState.value })
      .then((res) => {
        request.resolve();
        onClose("create");
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        request.reject(error);
      });
  };

  return (
    <Modal.Body style={{ padding: 0, height: "100%", margin: 0 }}>
      <Flex column middle style={{ height: "100%" }}>
        <Flex
          style={{
            height: "100%",
            maxWidth: "500px",
            width: "100%",
            backgroundColor: COLORS.gray_100,
          }}
          column
        >
          <Flex
            column
            gap={24}
            style={{ padding: "16px 16px 0 16px", height: "100%" }}
          >
            <Flex>
              <IconButton
                appearance="subtle"
                onClick={() => onClose("close")}
                circle
                size="xs"
                icon={
                  <Icon
                    Element={ArrowBackIcon}
                    size={24}
                    fill={COLORS.secondary}
                  />
                }
              />
            </Flex>
            <Flex one center left column gap={24}>
              <InterTag
                text={trans("Enter a Code")}
                size={40}
                color={COLORS.secondary}
                bold
              />
              <Flex column>
                <InterTag
                  style={{ textWrap: "wrap" }}
                  text={trans("Enter your <strong>noytrall stay ID</strong>.")}
                  size={16}
                  color={COLORS.secondary}
                  asHTML
                />
                <InterTag
                  style={{ textWrap: "wrap" }}
                  text={trans(
                    "This ID is handed to you at the hotel's front desk and will be forwarded to your email address after your check-in."
                  )}
                  size={16}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex column gap={4}>
                <Input
                  ref={ref}
                  disabled={request.isLoading}
                  size="lg"
                  value={codeState.value}
                  onChange={(value) =>
                    setCodeState({
                      error: "",
                      value: value.slice(0, 6).toUpperCase(),
                    })
                  }
                  placeholder="HT5J3J"
                  autoComplete="off"
                />
                {codeState.error && (
                  <InterTag
                    color={COLORS.error}
                    size={10}
                    text={codeState.error}
                  />
                )}
              </Flex>
              <SimpleButton
                text={{
                  text: trans("Confirm").toUpperCase(),
                  color: COLORS.white,
                  size: 16,
                }}
                onClick={handleConfirm}
                appearance="primary"
                loading={request.isLoading}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  onClose(action: "create" | "close"): void;
}

const AddCodeModal: React.FC<iProps> = ({ onClose, ...props }) => {
  return (
    <Modal
      {...{ ...DEFAULT_MODAL_PROPS, onClose: () => onClose("close"), ...props }}
    >
      {props.open && <AddCodeModalWrapped {...{ onClose }} />}
    </Modal>
  );
};

export default AddCodeModal;
