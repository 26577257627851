import { cloneDeep } from "lodash";
import React, { useEffect, useReducer } from "react";
import { defaultHotel } from "../../utils/hotels";
import { defaultStay } from "../../utils/stays";
import { nGuest } from "./interfaces";

const GuestDispatchContext = React.createContext<
  nGuest.tDispatchContext | undefined
>(undefined);
GuestDispatchContext.displayName = "GuestDispatchContext";
const GuestStateContext = React.createContext<nGuest.tStateContext | undefined>(
  undefined
);
GuestStateContext.displayName = "GuestStateContext";

const LS_DATA = "__noytrall-ga-g__";

const initialState: nGuest.tState = {
  data: {
    stay: cloneDeep(defaultStay),
    hotel: cloneDeep(defaultHotel),
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nGuest.tState,
  action: nGuest.tAction
): nGuest.tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const GuestContextProvider: React.FC<nGuest.iContextProps> = ({ children }) => {
  const [state, dispatch]: [nGuest.tState, React.Dispatch<nGuest.tAction>] =
    useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "pending" });

    const stored = localStorage.getItem(LS_DATA);

    if (!stored) {
      return dispatch({ type: "rejected", error: "Not in storage" });
    }
    const data = JSON.parse(stored) as nGuest.tStateData;
    return dispatch({ type: "set data", data });
  }, []);

  return (
    <GuestStateContext.Provider value={state}>
      <GuestDispatchContext.Provider value={dispatch}>
        {children}
      </GuestDispatchContext.Provider>
    </GuestStateContext.Provider>
  );
};

export { GuestContextProvider, GuestDispatchContext, GuestStateContext };
