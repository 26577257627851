import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

export interface iApexChartProps {
  height?: number | string;
  width?: number | string;
  series: ApexOptions["series"];
  colors?: string[];
  categories?: any[];
  xaxis?: ApexOptions["xaxis"];
  yaxis?: ApexOptions["yaxis"];
  legend?: ApexOptions["legend"];
  grid?: ApexOptions["grid"];
  stroke?: ApexOptions["stroke"];
  tooltip?: ApexOptions["tooltip"];
  chart?: ApexOptions["chart"];
  dataLabels?: ApexOptions["dataLabels"];
  markers?: ApexOptions["markers"];
  plotOptions?: ApexOptions["plotOptions"];
  title?: ApexOptions["title"];
  type: "bar" | "line";
}

const Apex: React.FC<iApexChartProps> = ({
  type,
  height = 288,
  width = "100%",
  series,
  colors,
  categories,
  xaxis: _xaxis = {},
  yaxis: _yaxis = {},
  legend: _legend = {},
  grid: _grid = {},
  stroke: _stroke = {},
  tooltip: _tooltip = {},
  chart: _chart = {},
  dataLabels: _dataLabels = {},
  markers: _markers = {},
  plotOptions: _plotOptions = {},
  title: _title = {},
}) => {
  const xaxis: ApexOptions["xaxis"] = {
    categories,
    tooltip: { enabled: false },
    crosshairs: { show: false },
    axisBorder: { show: false },
    axisTicks: {
      show: false,
    },
    ..._xaxis,
  };
  const yaxis: ApexOptions["yaxis"] = {
    crosshairs: { show: false },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
      // color: LABEL_COLOR,
    },
    ..._yaxis,
  };
  const plotOptions: ApexOptions["plotOptions"] = { ..._plotOptions };
  const markers: ApexOptions["markers"] = { ..._markers };
  const chart: ApexOptions["chart"] = { toolbar: { show: false }, ..._chart };
  const dataLabels: ApexOptions["dataLabels"] = { ..._dataLabels };
  const stroke: ApexOptions["stroke"] = {
    ...(type === "line" ? { curve: "straight" } : {}),
    width: 2,
    ..._stroke,
  };
  const tooltip: ApexOptions["tooltip"] = { ..._tooltip };
  const legend: ApexOptions["legend"] = { ..._legend };
  const grid: ApexOptions["grid"] = {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    ..._grid,
  };
  const title: ApexOptions["title"] = { ..._title };

  return (
    <ReactApexChart
      width={width}
      height={height}
      type={type}
      series={series}
      options={{
        plotOptions,
        markers,
        chart,
        dataLabels,
        colors,
        stroke,
        xaxis,
        yaxis,
        tooltip,
        legend,
        grid,
        title,
      }}
    />
  );
};

export default Apex;
