import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputGroup } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/icons/visibility_off.svg";
import { ReactComponent as SignatureLogotypeIcon } from "../../../assets/signature-logotype.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { UserContextProvider } from "../../../context/User";
import useUserDispatch from "../../../context/User/hooks/useUserDispatch";
import useUserState from "../../../context/User/hooks/useUserState";
import WithContextProvider from "../../../hoc/withProvider";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState<string>("");
  const [visibility, setVisibility] = useState(false);
  const [tokenState, setTokenState] = useState<string | null>(null);
  const { setProfile } = useUserDispatch();
  const userState = useUserState();
  const { loginUser } = useAuthDispatch();

  const loginRequest = usePostRequest();

  useEffect(() => {
    if (userState.isResolved && tokenState) {
      loginUser(tokenState);
    }
  }, [loginUser, tokenState, userState.isResolved]);

  const handleLogin = () => {
    loginRequest.pending();
    axios
      .post("/guest-app/v1/login", { email, password })
      .then((res) => {
        const {
          data: { token, profile },
        } = res;
        setTokenState(token);
        setProfile(profile);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        loginRequest.reject(error);
      });
  };

  return (
    <Flex
      column
      style={{ padding: "16px 16px 0 16px", width: "100%" }}
      gap={64}
    >
      <Flex center>
        <Icon width={214} height={64} Element={SignatureLogotypeIcon} />
      </Flex>
      <Flex column gap={40}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <Flex column gap={24}>
            <InterTag
              text={trans("Login")}
              size={40}
              color={COLORS.secondary}
              bold
            />
            <Flex column gap={8}>
              <InputWrapper label={trans("Email")}>
                <InputGroup>
                  <InputGroup.Addon className={`bg-white`}>
                    <Icon
                      Element={MailIcon}
                      width={24}
                      height={24}
                      fill={COLORS.gray_400}
                    />
                  </InputGroup.Addon>
                  <Input
                    value={email}
                    onChange={setEmail}
                    type="text"
                    placeholder={trans("user@youremail.com")}
                    size="lg"
                  />
                </InputGroup>
              </InputWrapper>
              <InputWrapper label={trans("Password")}>
                <InputGroup>
                  <InputGroup.Addon className={`bg-white`}>
                    <Icon
                      Element={PasswordIcon}
                      width={24}
                      height={24}
                      fill={COLORS.gray_400}
                    />
                  </InputGroup.Addon>
                  <Input
                    value={password}
                    onChange={setPassword}
                    type={visibility ? "text" : "password"}
                    placeholder={trans("MySecureP@ssw0rd")}
                    size="lg"
                  />
                  <InputGroup.Button
                    className={`hover-bg-gray-100 bg-white`}
                    onClick={() => setVisibility((prev) => !prev)}
                  >
                    {visibility ? (
                      <Icon
                        Element={VisibilityIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray}
                      />
                    ) : (
                      <Icon
                        Element={VisibilityOffIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray}
                      />
                    )}
                  </InputGroup.Button>
                </InputGroup>
              </InputWrapper>
              <Flex right>
                <Button appearance="link" onClick={() => navigate("/forgot")}>
                  <InterTag
                    text={trans("Forgot your password?")}
                    color={COLORS.primary}
                    size={14}
                  />
                </Button>
              </Flex>
            </Flex>
            <SimpleButton
              loading={loginRequest.isLoading}
              appearance="primary"
              text={{
                text: trans("Continue").toUpperCase(),
                size: 14,
                color: COLORS.white,
              }}
              onClick={handleLogin}
            />
          </Flex>
          <input type="submit" style={{ display: "none" }} />
        </form>

        <Flex center>
          <Flex middle column>
            <InterTag
              text={trans("New to noytrall?")}
              color={COLORS.secondary}
              size={14}
            />
            <Flex middle row gap={4}>
              <SimpleButton
                style={{ padding: 0 }}
                appearance="link"
                text={{
                  text: trans("Register Now"),
                  size: 14,
                  color: COLORS.primary,
                }}
                onClick={() => navigate("/register")}
              />
              <InterTag size={14} text={trans("or")} />
              <SimpleButton
                style={{ padding: 0 }}
                appearance="link"
                text={{
                  text: trans("Login as a Guest"),
                  size: 14,
                  color: COLORS.primary,
                }}
                onClick={() => navigate("/login-guest")}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WithContextProvider(UserContextProvider, Login);
